import React, { useEffect, useState } from 'react';
import * as L from '../styled/layout';
import {
  brandDarkBlueColor,
  brandGreenColor,
  headerHeight,
  redColor,
} from '../../globals';
import { Sidebar } from '../Sidebar/Sidebar';
import { ContentCard } from '../Content/ContentCard';
import { ContentHeader } from '../Content/ContentHeader';
import { useQuery } from 'react-query';
import {
  TakeTestSelectedAnswersProp,
  TakeFinalTestFullData,
} from '../../types';
import axios from 'axios';
import { API_URL } from '../../index';
import { ContentFooter } from '../Content/ContentFooter';
import { Button } from '../Button/Button';
import { TakeTestQuestion } from './TakeTestQuestion';
import { TakeTestResult } from './TakeTestResult';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { useLanguage } from '../../language/useLanguage';
import moment from 'moment';

const TakeFinalTestScreen = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const {
    data: testData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<TakeFinalTestFullData>(`take-final-test-data`, () =>
    axios.get(`${API_URL}/final-test/load/`).then((res) => res.data),
  );
  const currentQuestion =
    testData && testData.questions && testData.questions[currentQuestionIndex]
      ? testData.questions[currentQuestionIndex]
      : null;

  const [testFinished, setTestFinished] = useState(false);
  const { t } = useLanguage();

  const [selectedAnswers, setSelectedAnswers] =
    useState<TakeTestSelectedAnswersProp>({});

  const resetAnswers = () => {
    const selectedAnswersTmp: TakeTestSelectedAnswersProp = {};

    if (testData && testData.questions && Array.isArray(testData.questions))
      testData.questions.forEach((q) => {
        if (q.questionType === 'correctOrder') {
          selectedAnswersTmp[q.id] = q.answers.map((a) => a.id);
        } else {
          selectedAnswersTmp[q.id] = [];
        }
      });

    setSelectedAnswers(selectedAnswersTmp);
  };

  useEffect(() => {
    resetAnswers();
  }, [testData]);

  const onAnswerUpdate = (questionId: number, answers: number[]) => {
    const newSelectedAnswers = { ...selectedAnswers };
    newSelectedAnswers[questionId] = answers;
    setSelectedAnswers(newSelectedAnswers);
  };

  const onFinishTestClick = () => {
    setTestFinished(true);
  };

  const onTakeTestAgainClick = () => {
    refetch();
    setTestFinished(false);
    resetAnswers();
    setCurrentQuestionIndex(0);
  };

  const formatSeconds = (seconds: number) => {
    if (seconds >= 60 * 60) {
      return `${Math.ceil(seconds / 60 / 60)}h`;
    }

    return `${Math.ceil(seconds / 60)} min`;
  };

  if (!testData) {
    return (
      <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
        <Sidebar />
        <ContentCard>&nbsp;</ContentCard>
      </L.Absolute>
    );
  }

  if (testData.testPassed) {
    return (
      <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
        <Sidebar />
        <ContentCard>
          <ContentHeader>{t('finalTestStatus')}</ContentHeader>
          <div style={{ padding: 30, paddingTop: 0 }}>
            <h1>
              <span
                className="fa fa-check"
                style={{ color: brandGreenColor, marginRight: 12 }}
              />
              {t('testPassed')}
            </h1>

            <span className="fa fa-clock-o" style={{ marginRight: 6 }} />
            {moment(testData.testDate).format('DD.MM.YYYY, HH:mm')}

            {testData.certificateKey ? (
              <a
                style={{
                  display: 'block',
                  marginTop: 20,
                  marginBottom: 20,
                  backgroundColor: '#F8F8F8',
                  padding: 10,
                  cursor: 'pointer',
                  border: '1px solid #DDD',
                  borderRadius: 4,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
                href={`${API_URL}/certificate/download?c=${testData.certificateKey}`}
                target="_blank"
              >
                <span className="fa fa-download" /> {t('downloadCertificate')}
              </a>
            ) : null}
          </div>
        </ContentCard>
      </L.Absolute>
    );
  }

  if (!testData.testAvailable) {
    return (
      <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
        <Sidebar />
        <ContentCard>
          <ContentHeader>{t('finalTestStatus')}</ContentHeader>
          <div style={{ padding: 30, paddingTop: 0 }}>
            <h1>
              <span
                className="fa fa-remove"
                style={{ color: redColor, marginRight: 12 }}
              />
              {t('finalTestIsNotAvailable')}
            </h1>

            {testData.nextTestAvailableInSeconds ? (
              <div>
                {t('availableAfter')}{' '}
                {formatSeconds(testData.nextTestAvailableInSeconds)}
              </div>
            ) : null}
          </div>
        </ContentCard>
      </L.Absolute>
    );
  }

  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <Sidebar />
      <ContentCard>
        <ContentHeader>
          <L.FlexDiv horizontal>
            <L.FlexDiv flex>{t('finalTest')}</L.FlexDiv>
            <div>
              {currentQuestionIndex + 1}/{testData?.questions.length || 1}
            </div>
          </L.FlexDiv>
          <L.Spacer top={12}>
            <ProgressBar
              percent={
                ((currentQuestionIndex + 1) /
                  (testData?.questions.length || 1)) *
                100
              }
            />
          </L.Spacer>
        </ContentHeader>

        <div style={{ padding: 30, paddingTop: 15 }}>
          {testFinished ? (
            <TakeTestResult
              selectedAnswers={selectedAnswers}
              onTakeTestAgainClick={onTakeTestAgainClick}
              finalTest={true}
              checksum={testData.checksum}
            />
          ) : (
            <div>
              {currentQuestion && !isLoading && !isFetching ? (
                <TakeTestQuestion
                  selectedAnswers={selectedAnswers[currentQuestion.id] || []}
                  question={currentQuestion}
                  onAnswerUpdate={(answers) =>
                    onAnswerUpdate(currentQuestion.id, answers)
                  }
                />
              ) : null}
            </div>
          )}
        </div>

        {!testFinished ? (
          <ContentFooter>
            <L.FlexDiv horizontal>
              <L.FlexDiv horizontal centerV>
                {currentQuestionIndex ? (
                  <a
                    onClick={() =>
                      setCurrentQuestionIndex(currentQuestionIndex - 1)
                    }
                    style={{ color: brandDarkBlueColor }}
                  >
                    <span
                      className="fa fa-chevron-left"
                      style={{ marginRight: 5 }}
                    />
                    {t('previousQuestion')}
                  </a>
                ) : null}
              </L.FlexDiv>

              <L.FlexDiv flex />
              <div style={{ width: 200 }}>
                {currentQuestionIndex >=
                (testData?.questions.length || 0) - 1 ? (
                  <Button title={t('finishTest')} onClick={onFinishTestClick} />
                ) : null}

                {currentQuestionIndex <
                (testData?.questions.length || 0) - 1 ? (
                  <Button
                    title={t('nextQuestion')}
                    onClick={() =>
                      setCurrentQuestionIndex(currentQuestionIndex + 1)
                    }
                  />
                ) : null}
              </div>
            </L.FlexDiv>
          </ContentFooter>
        ) : null}
      </ContentCard>
    </L.Absolute>
  );
};

export { TakeFinalTestScreen };
