import React, { useState } from 'react';
import axios from 'axios';
import {
  SortableContainer,
  SortableElement,
  SortEnd,
} from 'react-sortable-hoc';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import arrayMove from 'array-move';
import Modal from 'react-modal';
import * as L from '../styled/layout';
import {
  brandBlueColor,
  brandDarkBlueColor,
  headerHeight,
} from '../../globals';

import { Sidebar } from '../Sidebar/Sidebar';
import { ContentCard } from '../Content/ContentCard';
import { ContentHeader } from '../Content/ContentHeader';
import { List } from '../List/List';
import { ListItem } from '../List/ListItem';
import { TestQuestionAnswer, TestQuestionFullData } from '../../types';
import { API_URL } from '../../index';
import { AdminBar } from '../common/AdminBar';
import { EditableTitle } from '../MyEditor/EditableTitle';
import { Button } from '../Button/Button';
import { ContentFooter } from '../Content/ContentFooter';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { EditableRichText } from '../MyEditor/EditableRichText';
import { File } from '../File/File';
import { FileUploadWithQueue } from '../File/FileUploadWithQueue';
import { Spinner } from '../common/Spinner';

const SortableItem = SortableElement(
  ({
    answer,
    listIndex,
    questionCount,
    onItemClick,
  }: {
    answer: TestQuestionAnswer;
    listIndex: number;
    questionCount: number;
    onItemClick: (id: number, text: string) => void;
  }) => (
    <ListItem
      key={answer.id}
      first={listIndex === 0}
      last={listIndex === questionCount - 1}
      title={answer.answerText}
      bold
      icon="arrows-v"
      showCheckBox={false}
      initialCheckboxStatus={answer.correct}
      onClick={() => onItemClick(answer.id, answer.answerText)}
    />
  ),
);

const SortableList = SortableContainer(
  ({
    answers,
    onItemClick,
  }: {
    answers: TestQuestionAnswer[];
    onItemClick: (id: number, text: string) => void;
  }) => {
    return (
      <div>
        {answers.map((answer, index) => (
          <SortableItem
            key={`item-${answer.id}`}
            answer={answer}
            index={index}
            listIndex={index}
            questionCount={answers.length}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    );
  },
);

const TestQuestionScreen = () => {
  const history = useHistory();
  const queryCache = useQueryCache();

  const [adminModeEnabled, setAdminModeEnabled] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showAddAnswerModal, setShowAddAnswerModal] = useState(false);
  const [newAnswerText, setNewAnswerText] = useState('');
  const [newAnswerCorrect, setNewAnswerCorrect] = useState(false);

  const [showEditAnswerModal, setShowEditAnswerModal] = useState(false);
  const [editAnswerText, setEditAnswerText] = useState('');
  const [editAnswerId, setEditAnswerId] = useState(0);

  const [changingQuestionType, setChangingQuestionType] = useState(false);

  const routeParams = useParams<{ id: string; questionId: string }>();
  const questionId = Number(routeParams.questionId);
  const { data: testQuesitonData, refetch } = useQuery<TestQuestionFullData>(
    `test-data-question-${questionId}`,
    () =>
      axios
        .get(`${API_URL}/test-question/${questionId}`)
        .then((res) => res.data),
  );

  const correctOrderQuestion =
    testQuesitonData?.data.questionType === 'correctOrder' ? true : false;

  type SavePayload = {
    questionText?: string;
    description?: string;
    questionType?: string;
  };

  const [saveQuestion] = useMutation((params: SavePayload) => {
    return axios
      .put(`${API_URL}/test-question/${questionId}`, params)
      .then((res) => res.data);
  });

  const [deleteQuestion] = useMutation(() =>
    axios
      .delete(`${API_URL}/test-question/${questionId}`)
      .then((res) => res.data),
  );

  const [addAnswer] = useMutation(() =>
    axios
      .post(`${API_URL}/test-question-answer/`, {
        questionId: questionId,
        answerText: newAnswerText,
        correct: newAnswerCorrect,
      })
      .then((res) => res.data),
  );

  const [updateAnswerOrder] = useMutation(
    (sortList: { id: number; sortNr: number }[]) =>
      axios
        .put(`${API_URL}/test-question-answer/update-order`, {
          sortList,
        })
        .then((res) => res.data),
  );

  const [updateAnswer] = useMutation(
    (params: { id: number; answerText?: string; correct?: boolean }) =>
      axios
        .put(`${API_URL}/test-question-answer/${params.id}`, {
          ...(params.answerText !== undefined && {
            answerText: params.answerText,
          }),
          ...(params.correct !== undefined && { correct: params.correct }),
        })
        .then((res) => res.data),
  );

  const [deleteAnswer] = useMutation(() =>
    axios
      .delete(`${API_URL}/test-question-answer/${editAnswerId}`)
      .then((res) => res.data),
  );

  const onSaveClick = async (params: SavePayload) => {
    await saveQuestion(params);
    await refetch();
    if (testQuesitonData) {
      await queryCache.invalidateQueries(
        `test-data-${testQuesitonData.testData.id}`,
      );
    }
  };

  const onAddAnswer = async () => {
    await addAnswer();
    await refetch();
    setShowAddAnswerModal(false);
  };

  const onSaveAnswer = async () => {
    await updateAnswer({
      id: editAnswerId,
      answerText: editAnswerText,
    });
    await refetch();
    setShowEditAnswerModal(false);
  };

  const onDeleteConfirm = async () => {
    await deleteQuestion();
    if (testQuesitonData) {
      await queryCache.invalidateQueries(
        `test-data-${testQuesitonData.testData.id}`,
      );
      history.push(`/test/${testQuesitonData?.testData.id}`);
    }
  };

  const onCorrectAnswerUpdate = async (id: number, correct: boolean) => {
    await updateAnswer({
      id,
      correct,
    });
    if (testQuesitonData) {
      await queryCache.invalidateQueries(
        `test-data-question-${testQuesitonData.data.id}`,
      );
    }
  };

  const onAnswerClick = (id: number, text: string) => {
    if (adminModeEnabled) {
      setShowEditAnswerModal(true);
      setEditAnswerId(id);
      setEditAnswerText(text);
    }
  };

  const onDeleteAnswerClick = async () => {
    await deleteAnswer();
    if (testQuesitonData) {
      await queryCache.invalidateQueries(
        `test-data-question-${testQuesitonData.data.id}`,
      );
    }
    setShowEditAnswerModal(false);
  };

  const onCorrectOrderCheckboxClick = async () => {
    setChangingQuestionType(true);
    await saveQuestion({
      questionType: correctOrderQuestion ? 'singleAnswer' : 'correctOrder',
    });
    await refetch();
    setChangingQuestionType(false);
  };

  const onOrderChanged = async (e: SortEnd) => {
    if (adminModeEnabled) {
      const { oldIndex, newIndex } = e;
      const list = testQuesitonData?.answers || [];
      const orderedList = arrayMove(list, oldIndex, newIndex);
      if (testQuesitonData) {
        const fullDataUpdated = { ...testQuesitonData, answers: orderedList };
        queryCache.setQueryData(
          `test-data-question-${questionId}`,
          fullDataUpdated,
        );

        const sortList = orderedList.map((item, index) => ({
          id: item.id,
          sortNr: index,
        }));

        await updateAnswerOrder(sortList);
        await refetch();
      }
    }
  };

  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <Sidebar />
      <ContentCard>
        <ContentHeader>
          <L.FlexDiv horizontal>
            <L.FlexDiv flex>
              <Link to={`/test/${testQuesitonData?.testData.id}`}>
                {testQuesitonData?.moduleData.name} &gt;{' '}
                {testQuesitonData?.testData.name} &gt;{' '}
                <b>{testQuesitonData?.data.questionText}</b>
              </Link>
            </L.FlexDiv>
            <div>
              {testQuesitonData?.currentQuestionNumber}/
              {testQuesitonData?.totalQuestionCount}
            </div>
          </L.FlexDiv>
          <L.Spacer top={12}>
            <ProgressBar
              percent={
                ((testQuesitonData?.currentQuestionNumber || 0) /
                  (testQuesitonData?.totalQuestionCount || 1)) *
                100
              }
            />
          </L.Spacer>
        </ContentHeader>

        <div style={{ padding: 30, paddingTop: 15 }}>
          <AdminBar
            enabled={adminModeEnabled}
            onModeChange={() => setAdminModeEnabled(!adminModeEnabled)}
          />

          <EditableTitle
            enableEditButton={adminModeEnabled}
            text={testQuesitonData?.data.questionText || ''}
            preset="h1"
            onSave={(questionText) => onSaveClick({ questionText })}
          />

          <EditableRichText
            html={testQuesitonData?.data.description || ''}
            onSave={(description) => onSaveClick({ description })}
            enableEditButton={adminModeEnabled}
            files={testQuesitonData?.files}
          />

          {adminModeEnabled ? (
            <div>
              {changingQuestionType ? (
                <Spinner />
              ) : (
                <input
                  type="checkbox"
                  checked={correctOrderQuestion}
                  onChange={onCorrectOrderCheckboxClick}
                />
              )}{' '}
              Correct order question
            </div>
          ) : correctOrderQuestion ? (
            'Sort questions in correct order'
          ) : (
            'Choose correct answer'
          )}

          <div style={{ marginTop: 20 }}>
            <List>
              {correctOrderQuestion ? (
                <SortableList
                  answers={testQuesitonData?.answers || []}
                  onSortEnd={onOrderChanged}
                  onItemClick={(id, text) => onAnswerClick(id, text)}
                  distance={5}
                />
              ) : (
                testQuesitonData?.answers.map((answer, index) => (
                  <ListItem
                    key={answer.id}
                    first={index === 0}
                    last={index === testQuesitonData.answers.length - 1}
                    title={answer.answerText}
                    bold
                    icon={answer.correct ? 'check' : undefined}
                    metaText={answer.correct ? 'Correct answer' : ''}
                    showCheckBox={adminModeEnabled}
                    initialCheckboxStatus={answer.correct}
                    checkboxStatus={answer.correct}
                    onCheckBoxChange={(checked) =>
                      onCorrectAnswerUpdate(answer.id, checked)
                    }
                    onClick={() => onAnswerClick(answer.id, answer.answerText)}
                  />
                ))
              )}
            </List>
          </div>
          {adminModeEnabled ? (
            <div style={{ marginTop: 20 }}>
              <a
                style={{ color: brandBlueColor }}
                onClick={() => {
                  setShowAddAnswerModal(true);
                  setNewAnswerText('');
                }}
              >
                <span className="fa fa-plus" style={{ marginRight: 5 }} />
                Add new answer
              </a>
            </div>
          ) : null}

          {testQuesitonData?.files.filter(
            (file) => file.showInList || adminModeEnabled,
          ).length ? (
            <div>
              <h3>Files</h3>
              <List>
                {testQuesitonData?.files
                  .filter((file) => file.showInList || adminModeEnabled)
                  .map((file, index) => (
                    <File
                      key={file.id}
                      data={file}
                      showFileAdminButtons={adminModeEnabled}
                      first={index === 0}
                      last={index === testQuesitonData.files.length - 1}
                      onFileUpdated={() =>
                        queryCache.invalidateQueries(
                          `test-data-question-${questionId}`,
                        )
                      }
                    />
                  )) || null}
              </List>
            </div>
          ) : null}
          {adminModeEnabled ? (
            <div style={{ paddingTop: 10 }}>
              <FileUploadWithQueue
                contentModule="test_question"
                contentId={testQuesitonData?.data.id || 0}
                onUploadFinished={() =>
                  queryCache.invalidateQueries(
                    `test-data-question-${questionId}`,
                  )
                }
              />
              <div style={{ paddingTop: 10, color: '#BBB' }}>
                Please do not upload any private/internal files here!
              </div>
            </div>
          ) : null}
        </div>

        {adminModeEnabled && (
          <div>
            <div
              style={{ textAlign: 'center', padding: 20 }}
              onClick={() => setShowDeleteConfirm(true)}
            >
              <a style={{ color: '#e74c3c' }}>Delete question</a>
            </div>
          </div>
        )}

        <ContentFooter>
          <L.FlexDiv horizontal>
            <L.FlexDiv horizontal centerV>
              {testQuesitonData?.previousQuestionData ? (
                <Link
                  to={`/test/${testQuesitonData.testData.id}/q/${testQuesitonData.previousQuestionData.id}`}
                  style={{ color: brandDarkBlueColor }}
                >
                  <span
                    className="fa fa-chevron-left"
                    style={{ marginRight: 5 }}
                  />
                  Previous question
                </Link>
              ) : null}
            </L.FlexDiv>

            <L.FlexDiv flex />
            <div style={{ width: 200 }}>
              {testQuesitonData?.nextQuestionData ? (
                <Link
                  to={`/test/${testQuesitonData.testData.id}/q/${testQuesitonData.nextQuestionData.id}`}
                >
                  <Button
                    title="Next question"
                    onClick={() => console.log('next')}
                  />
                </Link>
              ) : null}
            </div>
          </L.FlexDiv>
        </ContentFooter>

        {showAddAnswerModal && (
          <Modal
            isOpen={true}
            onRequestClose={() => setShowAddAnswerModal(false)}
            contentLabel="Example Modal"
            style={modalStyle}
          >
            <div>
              <div style={{ fontWeight: 'bold' }}>Answer</div>
              <input
                style={titleInputStyle}
                value={newAnswerText}
                onChange={(e) => setNewAnswerText(e.target.value)}
              />
            </div>

            <Button title="Add" onClick={onAddAnswer} />
          </Modal>
        )}

        {showEditAnswerModal && (
          <Modal
            isOpen={true}
            onRequestClose={() => setShowEditAnswerModal(false)}
            contentLabel="Example Modal"
            style={modalStyle}
          >
            <div>
              <div style={{ fontWeight: 'bold' }}>Answer</div>
              <input
                style={titleInputStyle}
                value={editAnswerText}
                onChange={(e) => setEditAnswerText(e.target.value)}
              />
            </div>

            <Button title="Save" onClick={onSaveAnswer} />

            <div
              style={{ textAlign: 'center', padding: 20 }}
              onClick={onDeleteAnswerClick}
            >
              <a style={{ color: '#e74c3c' }}>Delete answer</a>
            </div>
          </Modal>
        )}

        <Modal
          isOpen={showDeleteConfirm}
          onRequestClose={() => setShowDeleteConfirm(false)}
          contentLabel="Example Modal"
          style={modalStyle}
        >
          <div style={{ textAlign: 'center' }}>
            <h2>Delete this question?</h2>
            <div style={{ marginBottom: 24 }}>This it not revertable!</div>

            <Button
              title="Yes, delete"
              backgroundColor="#e74c3c"
              onClick={onDeleteConfirm}
            />
            <div
              style={{
                textAlign: 'center',
                marginTop: 12,
                color: '#999',
                cursor: 'pointer',
              }}
              onClick={() => setShowDeleteConfirm(false)}
            >
              Cancel
            </div>
          </div>
        </Modal>
      </ContentCard>
    </L.Absolute>
  );
};

const titleInputStyle = {
  padding: 10,
  fontSize: '2em',
  fontWeight: 'bold' as 'bold',
  marginTop: 15,
  marginBottom: 15,
  borderRadius: 5,
  border: '1px solid #DDD',
  width: '100%',
};

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 3px #DDD',
    width: 450,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
};

export { TestQuestionScreen };
