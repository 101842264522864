import React from 'react';
import * as L from '../styled/layout';
import * as S from '../styled/styled';
import { SidebarButton } from '../Sidebar/SidebarButton';
import { useLocation } from 'react-router-dom';

const MyProfileSidebar = () => {
  const location = useLocation();

  return (
    <L.FlexDiv w={320} mH={30} mV={30}>
      <S.Card style={{ marginBottom: 20 }}>
        <SidebarButton
          title="My profile"
          active={location.pathname === '/my-profile'}
          path="/support"
        />
        <SidebarButton
          title="Back to course"
          active={location.pathname === '/overview'}
          path="/overview"
        />
      </S.Card>
    </L.FlexDiv>
  );
};

export { MyProfileSidebar };
