import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { brandBlueColor, headerHeight } from '../../globals';
import { Sidebar } from '../Sidebar/Sidebar';
import { ContentCard } from '../Content/ContentCard';
import { ContentHeader } from '../Content/ContentHeader';
import { List } from '../List/List';
import { ListItem } from '../List/ListItem';
import { API_URL } from '../../index';
import { Module, ModuleFullData } from '../../types';
import { Button } from '../Button/Button';
import Modal from 'react-modal';
import { EditableTitle } from '../MyEditor/EditableTitle';
import { AdminBar } from '../common/AdminBar';
import { EditableRichText } from '../MyEditor/EditableRichText';
import axios from 'axios';
import { FileUploadWithQueue } from '../File/FileUploadWithQueue';
import { File } from '../File/File';
import { useSession } from '../../hooks/useSession';
import { useLanguage } from '../../language/useLanguage';
import { useProgressData } from '../../hooks/useProgressData';
import { useProgressMutation } from '../../hooks/useProgressMutation';

Modal.setAppElement('#root');

const ModuleScreen = () => {
  const queryCache = useQueryCache();
  const containerRef = useRef<HTMLDivElement>(null);
  const routeParams = useParams<{ id: string }>();
  const id = Number(routeParams.id);
  const [adminModeEnabled, setAdminModeEnabled] = useState(false);
  const { t } = useLanguage();
  const { data: progressData } = useProgressData();
  const [updateProgress] = useProgressMutation();

  const completedLessons: number[] = progressData?.lessonsCompleted || [];
  const completedTests: number[] = progressData?.testsCompleted || [];

  const [showAddNewLessonModal, setShowAddNewLessonModal] = useState(false);
  const [newLessonName, setNewLessonName] = useState('');

  const [showAddNewTestModal, setShowAddNewTestModal] = useState(false);
  const [newTestName, setNewTestName] = useState('');
  const [newTestQuestionCount, setNewTestQuestionCount] = useState('');
  const { session } = useSession();
  const adminLevel = session.adminLevel || 0;

  const { data: moduleList } = useQuery<{
    list: Module[];
  }>('module-list', () =>
    axios.get(`${API_URL}/module/list`).then((res) => res.data),
  );

  const { data: moduleData, refetch } = useQuery<ModuleFullData>(
    `module-data-${id}`,
    () => axios.get(`${API_URL}/module/${id}`).then((res) => res.data),
  );

  const moduleType = moduleData?.data.type || 'regular';

  type ModuleSavePayload = {
    name?: string;
    goal?: string;
    description?: string;
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [id]);

  const [saveModule] = useMutation((params: ModuleSavePayload) =>
    axios.put(`${API_URL}/module/${id}`, params).then((res) => res.data()),
  );

  const [addLesson] = useMutation(() =>
    axios
      .post(`${API_URL}/lesson/`, {
        name: newLessonName,
        moduleId: id,
      })
      .then((res) => res.data),
  );

  const [addTest] = useMutation(() =>
    axios
      .post(`${API_URL}/test/`, {
        name: newTestName,
        questionCount: Number(newTestQuestionCount)
          ? Number(newTestQuestionCount)
          : 0,
        moduleId: id,
      })
      .then((res) => res.data),
  );

  const onSaveClick = async (params: ModuleSavePayload) => {
    await saveModule(params);
    await refetch();
    await queryCache.invalidateQueries('module-list');
  };

  const onLessonAdd = async () => {
    await addLesson();
    await refetch();
    setShowAddNewLessonModal(false);
  };

  const onTestAdd = async () => {
    await addTest();
    await refetch();
    setShowAddNewTestModal(false);
  };

  const onReadStatusChange = async (lessonId: number) => {
    const read = completedLessons.includes(lessonId);
    await updateProgress({
      lessonId,
      status: !read,
    });
    await queryCache.invalidateQueries('progress-data');
  };

  const containerStyle: React.CSSProperties = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    top: headerHeight,
    right: 0,
    bottom: 0,
    left: 0,
    overflowY: 'scroll',
  };

  const titleInputStyle = {
    padding: 10,
    fontSize: '2em',
    fontWeight: 'bold' as 'bold',
    marginTop: 15,
    marginBottom: 15,
    borderRadius: 5,
    border: '1px solid #DDD',
    width: '100%',
  };

  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      boxShadow: '0px 0px 3px #DDD',
      width: 450,
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
  };

  return (
    <div style={containerStyle} ref={containerRef}>
      <Sidebar />
      <ContentCard>
        <ContentHeader>
          {moduleType === 'overview' ? t('overview') : t('moduleOverview')}
        </ContentHeader>
        <div style={{ padding: 30, paddingTop: 15 }}>
          <AdminBar
            enabled={adminModeEnabled}
            onModeChange={() => setAdminModeEnabled(!adminModeEnabled)}
          />
          <EditableTitle
            text={moduleData?.data.name || ''}
            preset="h1"
            onSave={(name) => onSaveClick({ name })}
            enableEditButton={adminModeEnabled}
          />

          {moduleType === 'regular' && (
            <div
              style={{
                backgroundColor: '#EAFAFD',
                padding: 10,
                marginBottom: 20,
                borderRadius: 4,
                border: '1px solid #C2EEF7',
              }}
            >
              <h3 style={{ marginTop: 0 }}>{t('goalOfThisModule')}</h3>

              <EditableRichText
                enableEditButton={adminModeEnabled}
                html={moduleData?.data.goal || ''}
                onSave={(goal) => onSaveClick({ goal })}
                files={moduleData?.files}
              />
            </div>
          )}

          <EditableRichText
            enableEditButton={adminModeEnabled}
            html={moduleData?.data.description || ''}
            onSave={(description) => onSaveClick({ description })}
            files={moduleData?.files}
          />

          {moduleType === 'overview' && (
            <List>
              {moduleList?.list
                .filter((m) => m.type !== 'overview')
                .map((module, index) => (
                  <ListItem
                    key={module.id}
                    first={index === 0}
                    last={index === moduleList.list.length - 1}
                    title={module.name}
                    icon="folder"
                    bold
                    metaIcon="file"
                    // metaText="0 lessons"
                    description={''}
                    path={`/module/${module.id}`}
                    // showProgress
                    progressPercent={1}
                  />
                ))}
            </List>
          )}

          {moduleType === 'regular' && (
            <>
              <h3>{t('lessons')}</h3>
              {/*<L.Spacer bottom={24}>*/}
              {/*  <ProgressBar*/}
              {/*    title="Module progress"*/}
              {/*    percent={0}*/}
              {/*    showLabel*/}
              {/*    boldTitle*/}
              {/*  />*/}
              {/*</L.Spacer>*/}
              <List>
                {moduleData?.lessons.map((lesson, index) => (
                  <ListItem
                    key={lesson.id}
                    first={index === 0}
                    last={index === moduleData.lessons.length - 1}
                    title={lesson.name}
                    // icon="file"
                    bold
                    // metaIcon="file"
                    metaText={lesson.readTime ? `${lesson.readTime} min` : ''}
                    path={`/lesson/${lesson.id}`}
                    showCheckBox={!session.adminLevel}
                    initialCheckboxStatus={completedLessons.includes(lesson.id)}
                    checkboxStatus={completedLessons.includes(lesson.id)}
                    onCheckBoxChange={() => onReadStatusChange(lesson.id)}
                  />
                ))}
              </List>
              {adminModeEnabled ? (
                <div style={{ marginTop: 20 }}>
                  <a
                    style={{ color: brandBlueColor }}
                    onClick={() => {
                      setShowAddNewLessonModal(true);
                      setNewLessonName('');
                    }}
                  >
                    <span className="fa fa-plus" style={{ marginRight: 5 }} />
                    Add new lesson
                  </a>
                </div>
              ) : null}
            </>
          )}

          {moduleType === 'regular' && (
            <>
              <h3>{t('tests')}</h3>
              <List>
                {moduleData?.tests.map((test, index) => (
                  <ListItem
                    key={test.id}
                    first={index === 0}
                    last={index === moduleData.tests.length - 1}
                    title={test.name}
                    icon="question-circle"
                    bold
                    // metaIcon="file"
                    metaText={`${test.questionCount} ${
                      test.questionCount === 1 ? t('question') : t('questions')
                    }`}
                    path={
                      adminLevel ? `/test/${test.id}` : `/take-test/${test.id}`
                    }
                    showCheckBox={
                      !session.adminLevel && completedTests.includes(test.id)
                    }
                    checkboxStatus={true}
                    onCheckBoxChange={() => console.log('test')}
                  />
                ))}
              </List>

              {adminModeEnabled ? (
                <div style={{ marginTop: 20 }}>
                  <a
                    style={{ color: brandBlueColor }}
                    onClick={() => {
                      setShowAddNewTestModal(true);
                      setNewTestName('');
                    }}
                  >
                    <span className="fa fa-plus" style={{ marginRight: 5 }} />
                    Add new test
                  </a>
                </div>
              ) : null}
            </>
          )}

          {moduleData?.files.filter(
            (file) => file.showInList || adminModeEnabled,
          ).length ? (
            <div>
              <h3>{t('files')}</h3>
              <List>
                {moduleData?.files
                  .filter((file) => file.showInList || adminModeEnabled)
                  .map((file, index) => (
                    <File
                      key={file.id}
                      data={file}
                      showFileAdminButtons={adminModeEnabled}
                      first={index === 0}
                      last={index === moduleData.files.length - 1}
                      onFileUpdated={() =>
                        queryCache.invalidateQueries(`module-data-${id}`)
                      }
                    />
                  )) || null}
              </List>
            </div>
          ) : null}

          {adminModeEnabled ? (
            <div style={{ paddingTop: 10 }}>
              <FileUploadWithQueue
                contentModule="module"
                contentId={moduleData?.data.id || 0}
                onUploadFinished={() =>
                  queryCache.invalidateQueries(`module-data-${id}`)
                }
              />
              <div style={{ paddingTop: 10, color: '#BBB' }}>
                Please do not upload any private/internal files here!
              </div>
            </div>
          ) : null}

          {showAddNewLessonModal && (
            <Modal
              isOpen={true}
              onRequestClose={() => setShowAddNewLessonModal(false)}
              contentLabel="Example Modal"
              style={modalStyle}
            >
              <div style={{ fontWeight: 'bold' }}>Name of lesson</div>
              <input
                style={titleInputStyle}
                value={newLessonName}
                onChange={(e) => setNewLessonName(e.target.value)}
              />
              <Button title="Add" onClick={onLessonAdd} />
            </Modal>
          )}

          {showAddNewTestModal && (
            <Modal
              isOpen={true}
              onRequestClose={() => setShowAddNewTestModal(false)}
              contentLabel="Example Modal"
              style={modalStyle}
            >
              <div>
                <div style={{ fontWeight: 'bold' }}>Name of test</div>
                <input
                  style={titleInputStyle}
                  value={newTestName}
                  onChange={(e) => setNewTestName(e.target.value)}
                />
              </div>

              <div>
                <div style={{ fontWeight: 'bold' }}>Question count</div>
                <input
                  style={titleInputStyle}
                  value={newTestQuestionCount}
                  onChange={(e) => setNewTestQuestionCount(e.target.value)}
                />
              </div>
              <Button title="Add" onClick={onTestAdd} />
            </Modal>
          )}
        </div>
      </ContentCard>
    </div>
  );
};

export { ModuleScreen };
