import React from 'react';
import * as L from '../styled/layout';
import { headerHeight } from '../../globals';
import { ContentCard } from '../Content/ContentCard';
import { ContentHeader } from '../Content/ContentHeader';
import { AdminSidebar } from './AdminSidebar';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../..';
import moment from 'moment';
import nl2br from 'react-nl2br';

const AdminFeedback = () => {
  const { data: feedbackData, isLoading } = useQuery<{
    list: {
      id: number;
      created: string;
      name: string;
      text: string;
      email: string;
    }[];
  }>(`feedback-list`, () =>
    axios.get(`${API_URL}/admin/feedback-list`).then((res) => res.data),
  );

  const feedbackList = feedbackData?.list || [];

  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <AdminSidebar />

      <ContentCard>
        <ContentHeader>Admin certificates</ContentHeader>
        {isLoading ? (
          <div style={{ padding: 30 }}>Loading...</div>
        ) : (
          <div style={{ padding: 30 }}>
            {feedbackList.length ? (
              feedbackList.map((c) => (
                <div
                  key={c.id}
                  style={{
                    borderRadius: 4,
                    border: '1px solid #DDD',
                    backgroundColor: '#F8F8F8',
                    marginBottom: 20,
                    padding: 12,
                  }}
                >
                  <div>
                    <b>{c.name}</b> ({c.email})
                  </div>
                  <div>{moment(c.created).format('DD.MM.YYYY, HH:mm')}</div>
                  <div style={{ marginTop: 10 }}>{nl2br(c.text)}</div>
                </div>
              ))
            ) : (
              <div>No feedback messages</div>
            )}
          </div>
        )}
      </ContentCard>
    </L.Absolute>
  );
};

export { AdminFeedback };
