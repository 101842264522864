import React, { ComponentProps } from 'react';
import { Route, Redirect } from 'react-router-dom';

const GuardedRoute = (
  props: ComponentProps<typeof Route> & { isAuthed?: boolean },
) => {
  if (!props.isAuthed) return <Redirect to="/" />;
  return <Route {...props} />;
};

export { GuardedRoute };
