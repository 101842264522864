import React from 'react';
import * as L from '../styled/layout';
import * as S from '../styled/styled';
import { headerHeight } from '../../globals';
import { ContentCard } from '../Content/ContentCard';
import { ContentHeader } from '../Content/ContentHeader';
import { AdminSidebar } from './AdminSidebar';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../..';
import moment from 'moment';

const AdminTestResults = () => {
  const { data: testData, isLoading } = useQuery<{
    list: {
      id: number;
      created: string;
      name: string;
      email: string;
      correctAnswers: number;
      totalQuestions: number;
      passed: number;
    }[];
  }>(`final-test-list`, () =>
    axios.get(`${API_URL}/admin/final-test-list`).then((res) => res.data),
  );

  const finalTestList = testData?.list || [];

  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <AdminSidebar />

      <ContentCard>
        <ContentHeader>Admin test results</ContentHeader>
        {isLoading ? (
          <div style={{ padding: 30 }}>Loading...</div>
        ) : (
          <div style={{ padding: 30 }}>
            {finalTestList.length ? (
              <table style={{ width: '100%' }}>
                <tr>
                  <td style={{ width: '25%' }}>User</td>
                  <td style={{ width: '25%' }}>Correct answers</td>
                  <td style={{ width: '25%' }}>Total questions</td>
                  <td style={{ width: '25%' }}>Date</td>
                </tr>

                {finalTestList.map((t) => (
                  <tr
                    key={t.id}
                    style={{
                      backgroundColor: Number(t.passed) ? '#d0efdd' : '#f3c3be',
                    }}
                  >
                    <td>
                      <div>{t.name}</div>
                      <div>{t.email}</div>
                    </td>
                    <td>{t.correctAnswers}</td>
                    <td>{t.totalQuestions}</td>
                    <td>{moment(t.created).format('DD.MM.YYYY, HH:mm')}</td>
                  </tr>
                ))}
              </table>
            ) : (
              <div>No tests taken</div>
            )}
          </div>
        )}
      </ContentCard>
    </L.Absolute>
  );
};

export { AdminTestResults };
