import React from 'react';
import * as L from '../styled/layout';
import * as S from '../styled/styled';
import { useWindowSize } from '../../hooks/useWindowSize';
import { mobileBreakPoint } from '../../globals';
import { useUIStore } from '../../hooks/useUIStore';

const ContentCard = (props: { children: React.ReactNode }) => {
  const { width: windowWidth } = useWindowSize();
  const desktopView = windowWidth >= mobileBreakPoint;
  const { showMenu } = useUIStore();

  if (!desktopView && showMenu) return null;

  return (
    <L.FlexDiv
      mV={30}
      style={{
        marginRight: desktopView ? 30 : 20,
        marginLeft: desktopView ? 0 : 20,
        wordBreak: 'break-word',
      }}
      flex
    >
      <S.Card style={{ marginBottom: 30 }}>{props.children}</S.Card>
    </L.FlexDiv>
  );
};

export { ContentCard };
