import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { FormInput } from '../Form/FormInput';
import { Button } from '../Button/Button';
import Modal from 'react-modal';
import * as L from '../styled/layout';
import * as S from '../styled/styled';
import { Flag, FlagContainer } from './Flag';
import { headerHeight, redColor } from '../../globals';
import { useLogin } from '../../hooks/useLogin';
import { AvailableLanguages, useLanguage } from '../../language/useLanguage';
import { useLanguageList } from '../../hooks/useLanguageList';
import { useWindowSize } from '../../hooks/useWindowSize';
import FacebookLogin from 'react-facebook-login';
import axios from 'axios';
import { API_URL } from '../../index';
import { FBLoginCheckRes, FBLoginRes, Module } from '../../types';
import { useSession } from '../../hooks/useSession';
import { FormSelect } from '../Form/FormSelect';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 3px #DDD',
    width: 450,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999,
  },
};

const LoginScreen = () => {
  const { width: windowWidth } = useWindowSize();
  const [login] = useLogin();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [linkPassword, setLinkPassword] = useState('');
  const [signupName, setSignupName] = useState('');
  const [error, setError] = useState(false);
  const [linkError, setLinkError] = useState('');
  const { data: languageList } = useLanguageList();
  const { t, changeLanguage, selectedLanguage } = useLanguage();
  const { updateSession } = useSession();
  const [showLinkModal, setShowLinkModal] = useState<{
    username: string;
    accessToken: string;
    userId: string;
  } | null>(null);
  const [showSignupModal, setShowSignupModal] = useState<{
    username: string;
    accessToken: string;
    userId: string;
  } | null>(null);

  const cardWidth = windowWidth > 540 ? 500 : windowWidth - 40;

  const onLoginClick = async () => {
    setLoading(true);

    const res = await login({
      username,
      password,
    });

    if (res && res.valid) {
      setError(false);
      setLoading(false);
      const moduleRes = await axios.get<{ list: Module[] }>(
        `${API_URL}/module/list`,
      );
      const moduleList = moduleRes.data.list || [];
      const overviewModule = moduleList.find((m) => m.type === 'overview');
      if (overviewModule) {
        history.push(`/module/${overviewModule.id}`);
      } else {
        history.push('/overview');
      }
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const onLoginWithFacebookRes = async (res: FBLoginRes) => {
    const fbResCheck = await axios.post<FBLoginCheckRes>(
      `${API_URL}/user/login-with-facebook`,
      res,
    );

    const fbResCheckData = fbResCheck.data;

    if (fbResCheckData) {
      // Login successfull
      if (fbResCheckData.valid) {
        updateSession({
          username: fbResCheckData.username,
          token: fbResCheckData.token,
          userId: fbResCheckData.userId,
          expires: fbResCheckData.expires,
          adminLevel: fbResCheckData.adminLevel,
          languageCode: fbResCheckData.languageCode || 'en',
        });
        setError(false);
        setLoading(false);
        const moduleRes = await axios.get<{ list: Module[] }>(
          `${API_URL}/module/list`,
        );
        const moduleList = moduleRes.data.list || [];
        const overviewModule = moduleList.find((m) => m.type === 'overview');
        if (overviewModule) {
          history.push(`/module/${overviewModule.id}`);
        } else {
          history.push('/overview');
        }
      } else if (
        fbResCheckData.error &&
        fbResCheckData.errorCode === 'fbAccountLinkRequired'
      ) {
        // show link popup
        setShowLinkModal({
          username: res.email,
          userId: res.userID,
          accessToken: res.accessToken,
        });
      } else if (
        fbResCheckData.error &&
        fbResCheckData.errorCode === 'fbSignupRequired'
      ) {
        setShowSignupModal({
          username: res.email,
          userId: res.userID,
          accessToken: res.accessToken,
        });
        setSignupName(res.name);
      }
    }
  };

  const onLinkAccounts = async () => {
    if (showLinkModal) {
      setLinkError('');

      const fbLinkRes = await axios.post<FBLoginCheckRes>(
        `${API_URL}/user/link-with-facebook`,
        {
          email: showLinkModal.username,
          accessToken: showLinkModal.accessToken,
          userID: showLinkModal.userId,
          wblPassword: linkPassword,
        },
      );

      const fbLinkResData = fbLinkRes.data;

      if (fbLinkResData.valid) {
        updateSession({
          username: fbLinkResData.username,
          token: fbLinkResData.token,
          userId: fbLinkResData.userId,
          expires: fbLinkResData.expires,
          adminLevel: fbLinkResData.adminLevel,
          languageCode: fbLinkResData.languageCode || 'en',
        });
        setError(false);
        setLoading(false);
        const moduleRes = await axios.get<{ list: Module[] }>(
          `${API_URL}/module/list`,
        );
        const moduleList = moduleRes.data.list || [];
        const overviewModule = moduleList.find((m) => m.type === 'overview');
        if (overviewModule) {
          history.push(`/module/${overviewModule.id}`);
        } else {
          history.push('/overview');
        }
      } else {
        setLinkError(t('wrongUsernameOrPassword'));
      }
    }
  };

  const onFBSignup = async () => {
    if (showSignupModal) {
      const primaryLanguageId =
        languageList?.find((lang) => lang.code === selectedLanguage)?.id || 1;

      const fbSignupRes = await axios.post<FBLoginCheckRes>(
        `${API_URL}/user/signup-with-facebook`,
        {
          email: showSignupModal.username,
          accessToken: showSignupModal.accessToken,
          userID: showSignupModal.userId,
          name: signupName,
          primaryLanguageId: primaryLanguageId,
        },
      );

      const fbSignupResData = fbSignupRes.data;

      if (fbSignupResData.valid) {
        updateSession({
          username: fbSignupResData.username,
          token: fbSignupResData.token,
          userId: fbSignupResData.userId,
          expires: fbSignupResData.expires,
          adminLevel: fbSignupResData.adminLevel,
          languageCode: fbSignupResData.languageCode || 'en',
        });
        setError(false);
        setLoading(false);
        const moduleRes = await axios.get<{ list: Module[] }>(
          `${API_URL}/module/list`,
        );
        const moduleList = moduleRes.data.list || [];
        const overviewModule = moduleList.find((m) => m.type === 'overview');
        if (overviewModule) {
          history.push(`/module/${overviewModule.id}`);
        } else {
          history.push('/overview');
        }
      }
    }
  };

  return (
    <L.Absolute
      top={headerHeight}
      centerH
      style={{ overflow: 'hidden', overflowY: 'scroll' }}
    >
      <S.Card w={cardWidth} mV={30}>
        <FlagContainer>
          {languageList?.map((lang) => (
            <Flag
              key={lang.code}
              country={lang.code}
              onClick={() => changeLanguage(lang.code)}
            />
          )) || null}
        </FlagContainer>
        <Form>
          {error ? (
            <div style={{ padding: 10, color: redColor, textAlign: 'center' }}>
              {t('wrongUsernameOrPassword')}
            </div>
          ) : null}
          <FormInput
            label={t('email')}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <FormInput
            label={t('password')}
            secureText
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            title={loading ? t('loggingIn') : t('login')}
            onClick={onLoginClick}
          />

          <div className="fb-button-wrapper">
            <FacebookLogin
              appId="3193712340849974"
              autoLoad={false}
              fields="name,email"
              callback={onLoginWithFacebookRes}
              size="medium"
              textButton={t('loginWithFacebook')}
            />
          </div>
        </Form>

        <ForgotPasswordContainer>
          <div style={{ paddingBottom: 12 }}>
            <Link to="/signup">{t('signupHere')}</Link>
          </div>
        </ForgotPasswordContainer>
      </S.Card>

      {showLinkModal ? (
        <Modal
          isOpen={!!showLinkModal}
          onRequestClose={() => setShowLinkModal(null)}
          contentLabel="Example Modal"
          style={modalStyle}
        >
          <div>
            <div style={{ marginBottom: 12 }}>{t('linkAccountsInfo')}</div>
            {linkError ? (
              <div
                style={{ padding: 10, color: redColor, textAlign: 'center' }}
              >
                {t('wrongUsernameOrPassword')}
              </div>
            ) : null}
            <div style={{ fontWeight: 'bold', marginBottom: 12 }}>
              {showLinkModal.username}
            </div>
            <FormInput
              label={t('password')}
              value={linkPassword}
              secureText
              onChange={(e) => setLinkPassword(e.target.value)}
            />

            <Button title={t('linkAccounts')} onClick={onLinkAccounts} />

            <div
              style={{
                textAlign: 'center',
                marginTop: 12,
                color: '#999',
                cursor: 'pointer',
              }}
              onClick={() => setShowLinkModal(null)}
            >
              {t('cancel')}
            </div>
          </div>
        </Modal>
      ) : null}

      {showSignupModal ? (
        <Modal
          isOpen={!!showSignupModal}
          onRequestClose={() => setShowSignupModal(null)}
          contentLabel="Example Modal"
          style={modalStyle}
        >
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: 12 }}>
              {showSignupModal.username}
            </div>
            <FormInput
              label={t('name')}
              value={signupName}
              onChange={(e) => setSignupName(e.target.value)}
            />

            {languageList ? (
              <FormSelect
                label={t('chooseLanguage')}
                value={selectedLanguage}
                onChange={(e) => {
                  const lang = e.target.value as AvailableLanguages;
                  changeLanguage(lang);
                }}
              >
                {languageList.map((language) => (
                  <option value={language.code} key={language.code}>
                    {language.name}
                  </option>
                ))}
              </FormSelect>
            ) : null}

            <Button title={t('signup')} onClick={onFBSignup} />

            <div
              style={{
                textAlign: 'center',
                marginTop: 12,
                color: '#999',
                cursor: 'pointer',
              }}
              onClick={() => setShowSignupModal(null)}
            >
              {t('cancel')}
            </div>
          </div>
        </Modal>
      ) : null}
    </L.Absolute>
  );
};

const Form = styled.form({
  padding: 30,
});

const ForgotPasswordContainer = styled.div({
  padding: 30,
  borderTop: '1px solid #EEE',
  textAlign: 'center' as 'center',
  color: '#B8B8B8',
});

export { LoginScreen };
