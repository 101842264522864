import React, { useState } from 'react';
import * as L from '../styled/layout';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { brandBlueColor, brandDarkBlueColor, redColor } from '../../globals';
import fileSize from 'filesize';
import { CheckBox } from '../CheckBox/CheckBox';
import { Spinner } from '../common/Spinner';
import { ConfirmDeleteModal } from '../common/ConfirmDeleteModal';
import { useMutation } from 'react-query';
import { API_URL } from '../../index';
import { MyFile } from '../../types';
import axios from 'axios';

const File = (props: {
  data: MyFile;
  onFileUpdated?: () => void;
  first?: boolean;
  last?: boolean;
  showFileAdminButtons?: boolean;
}) => {
  const { first, last } = props;
  const { id, name, size, url, showInList } = props.data;
  const [copied, setCopied] = useState(false);
  const [showInListStatusSaving, setShowInListStatusSaving] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState();

  const [deleteFile] = useMutation(() =>
    axios.delete(`${API_URL}/file/${id}`).then(res => res.data),
  );

  const [toggleShowInListStatus] = useMutation(() =>
    axios
      .put(`${API_URL}/file/${id}`, {
        showInList: !showInList,
      })
      .then(res => res.data),
  );

  const onClick = () => {
    window.open(url);
  };

  const onCopied = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const onCheckboxClick = async () => {
    setShowInListStatusSaving(true);
    await toggleShowInListStatus();
    if (props.onFileUpdated) {
      await props.onFileUpdated();
    }
    setShowInListStatusSaving(false);
  };

  const onDeleteConfirm = async () => {
    await deleteFile();
    if (props.onFileUpdated) {
      await props.onFileUpdated();
    }
    setShowDeleteConfirm(false);
  };

  return (
    <FileItemContainerStyled
      style={{
        ...(!first && { borderTop: 'none' }),
        ...(first && { borderTopLeftRadius: 4, borderTopRightRadius: 4 }),
        ...(last && {
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }),
      }}
    >
      <FileItemStyled>
        <L.FlexDiv horizontal onClick={onClick}>
          <div style={{ marginRight: 10 }}>
            <span className="fa fa-file" />
          </div>

          <L.FlexDiv flex style={{ fontWeight: 'bold' }}>
            {name}
          </L.FlexDiv>

          <div>{fileSize(size)}</div>
        </L.FlexDiv>
      </FileItemStyled>
      {props.showFileAdminButtons ? (
        <L.FlexDiv
          horizontal
          pH={10}
          style={{ paddingBottom: 10, fontSize: 13 }}
        >
          <L.FlexDiv horizontal centerV style={{ marginRight: 20 }}>
            {showInListStatusSaving ? (
              <Spinner color="green" marginRight={3} size={14} />
            ) : (
              <CheckBox
                checked={showInList}
                onClick={onCheckboxClick}
                size={14}
                marginRight={3}
              />
            )}
            Show in list
          </L.FlexDiv>

          <L.FlexDiv horizontal centerV style={{ marginRight: 20 }}>
            <CopyToClipboard text={url} onCopy={onCopied}>
              <a style={linkStyle}>
                <span className="fa fa-link" />{' '}
                {copied ? <span>Copied!</span> : <span>Copy link</span>}
              </a>
            </CopyToClipboard>
          </L.FlexDiv>

          <L.FlexDiv horizontal centerV style={{ marginRight: 20 }}>
            <a
              style={{ ...linkStyle, color: redColor }}
              onClick={() => setShowDeleteConfirm(true)}
            >
              <span className="fa fa-trash" /> Delete
            </a>
          </L.FlexDiv>
        </L.FlexDiv>
      ) : null}

      <ConfirmDeleteModal
        show={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onConfirm={onDeleteConfirm}
        text={`Do you want to delete ${name}?`}
      />
    </FileItemContainerStyled>
  );
};

const linkStyle = { cursor: 'pointer' };

const FileItemContainerStyled = styled.div({
  backgroundColor: '#F8F8F8',
  border: '1px solid #DDD',
});

const FileItemStyled = styled.a(props => ({
  color: brandDarkBlueColor,
  display: 'flex',
  flexDirection: 'column',
  padding: 10,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#F4F4F4',
    color: brandBlueColor,
  },
}));

export { File };
