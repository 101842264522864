import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';

import { useSession } from './useSession';
import { API_URL } from '../index';
import { AvailableLanguages } from '../language/useLanguage';

type SignupResult = {
  error: boolean;
  errorCode: string;
  userId: number;
  token: string;
  expires: string;
  adminLevel: number;
  languageCode: AvailableLanguages;
};

type SignupParams = {
  name: string;
  email: string;
  password: string;
  primaryLanguageId: number;
};

export const useSignup = () => {
  const { updateSession } = useSession();

  const signup = async (params: SignupParams) => {
    const res = await axios.post<SignupResult>(
      `${API_URL}/user/register`,
      params,
    );

    if (res.data.userId) {
      const sessionData = {
        username: params.name,
        token: res.data.token,
        userId: res.data.userId,
        expires: res.data.expires,
        adminLevel: res.data.adminLevel,
        languageCode: res.data.languageCode || 'en',
      };

      updateSession(sessionData);
    }

    return res.data;
  };

  return useMutation<SignupResult, AxiosError, SignupParams>(signup);
};
