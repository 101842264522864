import React from 'react';
import * as L from '../styled/layout';
import { brandBlueColorLighter } from '../../globals';
import { Link, useHistory } from 'react-router-dom';

const HeaderButton = (props: {
  title: string;
  icon?: string;
  path?: string;
  onClick?: () => void;
}) => {
  const history = useHistory();
  const { icon } = props;
  const showIcon = !!icon;

  const onClick = () => {
    if (props.path) {
      history.push(props.path);
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <L.Spacer left={20}>
      <a onClick={onClick} style={{ color: '#333' }}>
        {showIcon && (
          <span
            className={`fa fa-${icon}`}
            style={{ marginRight: 5, color: brandBlueColorLighter }}
          />
        )}
        {props.title}
      </a>
    </L.Spacer>
  );
};

export { HeaderButton };
