import React, { useState } from 'react';
import * as L from '../styled/layout';
import { brandOtherGreenColor, headerHeight } from '../../globals';
import { Sidebar } from '../Sidebar/Sidebar';
import { ContentCard } from '../Content/ContentCard';
import { ContentHeader } from '../Content/ContentHeader';
import { List } from '../List/List';
import { ListItem } from '../List/ListItem';
import { useQuery } from 'react-query';
import { Module } from '../../types';
import { API_URL } from '../../index';
import axios from 'axios';
import { useLanguage } from '../../language/useLanguage';

const OverviewScreen = () => {
  const { data: moduleList } = useQuery<{
    list: Module[];
  }>('module-list', () =>
    axios.get(`${API_URL}/module/list`).then((res) => res.data),
  );
  const { t } = useLanguage();

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <Sidebar />
      <ContentCard>
        <ContentHeader>{t('overview')}</ContentHeader>

        <div style={{ padding: 30, paddingTop: 15 }}>
          <h1>{t('welcomeTitle')}</h1>

          <List>
            {moduleList?.list.map((module, index) => (
              <ListItem
                key={module.id}
                first={index === 0}
                last={index === moduleList.list.length - 1}
                title={module.name}
                icon="folder"
                bold
                metaIcon="file"
                // metaText="0 lessons"
                description={''}
                path={`/module/${module.id}`}
                // showProgress
                progressPercent={1}
              />
            ))}
          </List>
        </div>
      </ContentCard>
    </L.Absolute>
  );
};

export { OverviewScreen };
