import React from 'react';
import styled from 'styled-components';
import {
  brandOtherGreenColor,
  brandOtherGreenColorDarker,
} from '../../globals';

const Button = (props: {
  title: string;
  onClick: () => void;
  backgroundColor?: string;
  backgroundColorHover?: string;
  href?: string;
  download?: boolean;
  target?: string;
}) => {
  const backgroundColor = props.backgroundColor || brandOtherGreenColor;
  const backgroundColorHover =
    props.backgroundColorHover || brandOtherGreenColorDarker;

  return (
    <ButtonStyled
      onClick={props.onClick}
      style={{ backgroundColor }}
      href={props.href}
      download={props.download || false}
      target={props.target}
    >
      {props.title}
    </ButtonStyled>
  );
};

const ButtonStyled = styled.a({
  display: 'block',
  backgroundColor: brandOtherGreenColor,
  padding: 15,
  borderRadius: 4,
  textAlign: 'center',
  color: '#FFF',
  fontWeight: 'bold',
  fontSize: 16,
  transition: '0.3s',

  '&:hover': {
    backgroundColor: brandOtherGreenColorDarker,
    color: '#FFF',
  },
});

export { Button };
