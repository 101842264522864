import React from 'react';
import Modal from 'react-modal';
import { Button } from '../Button/Button';

const ConfirmDeleteModal = (props: {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  text?: string;
}) => {
  if (!props.show) return null;

  return (
    <Modal
      isOpen={props.show}
      onRequestClose={props.onClose}
      contentLabel="Example Modal"
      style={modalStyle}
    >
      <div style={{ textAlign: 'center' }}>
        <h2>Confirm delete</h2>
        {props.text ? (
          <div style={{ marginBottom: 24 }}>{props.text}</div>
        ) : null}

        <Button
          title="Delete"
          backgroundColor="#e74c3c"
          onClick={props.onConfirm}
        />
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            color: '#999',
            cursor: 'pointer',
          }}
          onClick={props.onClose}
        >
          Cancel
        </div>
      </div>
    </Modal>
  );
};

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 3px #DDD',
    width: 450,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999,
  },
};

export { ConfirmDeleteModal };
