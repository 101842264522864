import React from 'react';
import * as L from '../styled/layout';
import { brandOtherGreenColor } from '../../globals';

const ProgressBar = (props: {
  percent?: number;
  title?: string;
  showLabel?: boolean;
  boldTitle?: boolean;
}) => {
  const { showLabel, title } = props;
  const percent = props.percent || 0;
  const showTitle = !!title;
  const showInfo = showTitle || showLabel;
  const label = `${percent}%`;

  const barStyle = {
    border: '1px solid #DDD',
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: '#EEE',
  };

  const fillStyle = {
    backgroundColor: brandOtherGreenColor,
    width: `${percent}%`,
    height: 16,
  };

  return (
    <div>
      {showInfo && (
        <L.FlexDiv horizontal style={{ marginBottom: 6 }}>
          {showTitle && (
            <L.FlexDiv
              flex
              style={{ fontWeight: props.boldTitle ? 'bold' : 'normal' }}
            >
              {title}
            </L.FlexDiv>
          )}
          {showLabel && (
            <div style={{ textAlign: 'right', color: '#AAA', fontSize: 13 }}>
              {label}
            </div>
          )}
        </L.FlexDiv>
      )}
      <div style={barStyle}>
        <div style={fillStyle} />
      </div>
    </div>
  );
};

export { ProgressBar };
