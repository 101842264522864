import { useMutation } from 'react-query';
import axios, { AxiosError } from 'axios';

import { useSession } from './useSession';
import { API_URL } from '../index';
import { AvailableLanguages } from '../language/useLanguage';

type LoginResult = {
  valid: boolean;
  token: string;
  expires: string;
  userId: number;
  adminLevel: number;
  languageCode: AvailableLanguages;
};

type LoginParams = {
  username: string;
  password: string;
};

export const useLogin = () => {
  const { updateSession } = useSession();

  const login = async (params: LoginParams) => {
    const { username, password } = params;

    const res = await axios.post<LoginResult>(`${API_URL}/user/login`, {
      username,
      password,
    });

    if (res.data.valid) {
      const sessionData = {
        username,
        token: res.data.token,
        userId: res.data.userId,
        expires: res.data.expires,
        adminLevel: res.data.adminLevel,
        languageCode: res.data.languageCode || 'en',
      };

      updateSession(sessionData);
    }

    return res.data;
  };

  return useMutation<LoginResult, AxiosError, LoginParams>(login);
};
