import React, { useState } from 'react';
import * as L from '../styled/layout';
import { useHistory } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import HTMLReactParser from 'html-react-parser';
import {
  brandBlueColor,
  brandDarkBlueColor,
  brandOtherGreenColor,
} from '../../globals';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { CheckBox } from '../CheckBox/CheckBox';

const ListItem = (props: {
  first?: boolean;
  last?: boolean;
  title: string;
  icon?: string;
  metaText?: string;
  metaIcon?: string;
  description?: string;
  path?: string;
  bold?: boolean;

  showProgress?: boolean;
  progressPercent?: number;
  progressLabel?: string;

  showCheckBox?: boolean;
  initialCheckboxStatus?: boolean;
  onCheckBoxChange?: (checked: boolean) => void;
  checkboxStatus?: boolean;
  checkboxStyle?: 'progress' | 'neutral';
  showFileAdminButtons?: boolean;

  onClick?: () => void;
}) => {
  const {
    title,
    icon,
    metaIcon,
    metaText,
    description,
    first,
    last,
    bold,
    showProgress,
    progressPercent,
    showCheckBox,
    initialCheckboxStatus,
  } = props;
  const path = props.path || '/';

  const history = useHistory();

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      if (path.startsWith('http://') || path.startsWith('https://')) {
        window.open(path);
      } else {
        history.push(path);
      }
    }
  };

  return (
    <ListItemStyled
      onClick={onClick}
      style={{
        ...(!first && { borderTop: 'none' }),
        ...(first && { borderTopLeftRadius: 4, borderTopRightRadius: 4 }),
        ...(last && { borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }),
      }}
    >
      <div>
        <L.FlexDiv horizontal>
          {icon && !showCheckBox ? (
            <div style={{ marginRight: 10 }}>
              <span className={`fa fa-${icon}`} />
            </div>
          ) : null}

          {showCheckBox && (
            <div style={{ marginRight: 10 }}>
              <CheckBox
                checked={props.checkboxStatus || false}
                checkBoxStyle={props.checkboxStyle}
                onClick={() => {
                  if (props.onCheckBoxChange) {
                    props.onCheckBoxChange(!props.checkboxStatus);
                  }
                }}
              />
            </div>
          )}

          <L.FlexDiv flex style={{ ...(bold && { fontWeight: 'bold' }) }}>
            {title}
          </L.FlexDiv>

          {metaText ? <div>{metaText}</div> : null}
        </L.FlexDiv>
        {props.showFileAdminButtons ? (
          <CopyToClipboard text={path}>
            <span>Copy link</span>
          </CopyToClipboard>
        ) : null}

        {description ? (
          <div
            style={{
              color: '#555',
              fontSize: 13,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {HTMLReactParser(description)}
          </div>
        ) : null}

        {showProgress && (!progressPercent || progressPercent < 100) && (
          <ProgressBar
            percent={progressPercent}
            title="You have started this module"
            showLabel
          />
        )}

        {showProgress && progressPercent && progressPercent >= 100 && (
          <div
            style={{
              color: '#FFF',
              fontWeight: 'bold',
              display: 'inline-block',
              backgroundColor: brandOtherGreenColor,
              borderRadius: 30,
              padding: '3px 6px',
              fontSize: 11,
            }}
          >
            <span className="fa fa-check" /> Completed
          </div>
        )}
      </div>
    </ListItemStyled>
  );
};

const ListItemStyled = styled.a((props) => ({
  color: brandDarkBlueColor,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F8F8F8',
  padding: 10,
  cursor: 'pointer',
  border: '1px solid #DDD',

  '&:hover': {
    backgroundColor: '#F4F4F4',
    color: brandBlueColor,
  },
}));

export { ListItem };
