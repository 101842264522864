import axios from 'axios';
import { useMutation } from 'react-query';
import { API_URL } from '..';

export const useProgressMutation = () => {
  return useMutation((params: { lessonId: number; status: boolean }) => {
    return axios
      .put(`${API_URL}/progress/markLessonAsRead`, params)
      .then((res) => res.data);
  });
};
