import React, { useState } from 'react';
import { AvailableLanguages } from '../language/useLanguage';

export const LanguageContext = React.createContext({
  lang: 'en' as AvailableLanguages,
  setLang: (lang: AvailableLanguages) => {},
});

export const LanguageContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const [lang, setLang] = useState<AvailableLanguages>('en');

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {props.children}
    </LanguageContext.Provider>
  );
};
