import React from 'react';
import * as S from '../styled/styled';
import * as L from '../styled/layout';
import { SidebarButton } from './SidebarButton';
import { useLanguage } from '../../language/useLanguage';
import { useQuery } from 'react-query';
import { LessonFullData, Module, TestFullData } from '../../types';
import axios from 'axios';
import { API_URL } from '../../index';
import { useLocation, useParams } from 'react-router-dom';
import { useSession } from '../../hooks/useSession';
import { useUIStore } from '../../hooks/useUIStore';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { useProgressData } from '../../hooks/useProgressData';

const MainMenuContent = (props: { showLogoutButton?: boolean }) => {
  const location = useLocation();
  const routeParams = useParams<{ id: string }>();
  const { destroySession } = useSession();
  const { showMenu, toggleMenu } = useUIStore();
  const { session } = useSession();
  const { data: progressData } = useProgressData();

  const id = Number(routeParams.id);

  const { t } = useLanguage();

  const { data: moduleList } = useQuery<{
    list: Module[];
  }>('module-list', () =>
    axios.get(`${API_URL}/module/list`).then((res) => res.data),
  );

  const overviewModule = moduleList?.list.find((m) => m.type === 'overview');

  const hideMenu = () => {
    if (showMenu) {
      toggleMenu();
    }
  };

  const lessonId = !location.pathname.includes('/lesson') ? 0 : id;

  const { data: lessonData } = useQuery<LessonFullData>(
    `lesson-data-${lessonId}`,
    () => {
      if (!lessonId) {
        return Promise.resolve(undefined);
      }

      return axios.get(`${API_URL}/lesson/${lessonId}`).then((res) => res.data);
    },
  );

  const testId = !location.pathname.includes('/test/') ? 0 : id;

  const { data: testData } = useQuery<TestFullData>(
    `test-data-${testId}`,
    () => {
      if (!testId) {
        return Promise.resolve(undefined);
      }

      return axios.get(`${API_URL}/test/${testId}`).then((res) => res.data);
    },
  );

  const progressPercent = Math.round((progressData?.progress || 0) * 100);
  const showFinalTestButton = progressData?.showFinalTestButton || false;

  return (
    <>
      {overviewModule ? (
        <S.Card style={{ marginBottom: 20 }}>
          <SidebarButton
            title={t('overview')}
            active={location.pathname === `/module/${overviewModule.id}`}
            path={`/module/${overviewModule.id}`}
            onClick={hideMenu}
          />
        </S.Card>
      ) : null}
      <S.Card>
        {moduleList?.list
          .filter((m) => m.type !== 'overview')
          .map((module) => (
            <SidebarButton
              key={module.id}
              title={module.name}
              active={
                (Number(routeParams.id) === module.id &&
                  location.pathname.includes('/module')) ||
                (Number(lessonData?.moduleData.id) === module.id &&
                  location.pathname.includes('/lesson')) ||
                (Number(testData?.moduleData.id) === module.id &&
                  location.pathname.includes('/test/'))
              }
              path={`/module/${module.id}`}
              onClick={hideMenu}
            />
          )) || null}
      </S.Card>

      {!session.adminLevel && progressData ? (
        <>
          <S.Card style={{ padding: 12, marginTop: 20 }}>
            <h3 style={{ margin: 0 }}>{t('yourProgress')}</h3>
            <L.Spacer v={6} bottom={12}>
              <ProgressBar percent={progressPercent} showLabel boldTitle />
            </L.Spacer>
          </S.Card>

          {showFinalTestButton ? (
            <S.Card style={{ marginTop: 20 }}>
              <SidebarButton
                title={t('finalTest')}
                active={location.pathname === '/final-test'}
                path="/final-test"
                onClick={hideMenu}
              />
            </S.Card>
          ) : null}
        </>
      ) : null}

      {props.showLogoutButton ? (
        <S.Card style={{ marginTop: 20, marginBottom: 20 }}>
          <SidebarButton
            key={module.id}
            title={t('logout')}
            path={`/`}
            onClick={() => {
              destroySession();
              hideMenu();
            }}
          />
        </S.Card>
      ) : null}
    </>
  );
};

export { MainMenuContent };
