import axios from 'axios';
import { useQuery } from 'react-query';
import { API_URL } from '..';
import { ProgressResult } from '../types';

export const useProgressData = () => {
  return useQuery<ProgressResult>(`progress-data`, () =>
    axios.get(`${API_URL}/progress`).then((res) => res.data),
  );
};
