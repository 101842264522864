import React, { useState } from 'react';
import axios from 'axios';
import { AvailableLanguages } from '../language/useLanguage';

export type SessionData = {
  username: string;
  token: string;
  userId: number;
  expires: string;
  adminLevel: number;
  languageCode: AvailableLanguages;
};

const initialSessionValue = {
  username: '',
  token: '',
  userId: 0,
  expires: '',
  adminLevel: 0,
  languageCode: 'en' as AvailableLanguages,
};

export const SessionContext = React.createContext({
  session: initialSessionValue,
  updateSession: (session: SessionData) => {},
  destroySession: () => {},
});

export const SessionContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const [session, setSession] = useState<SessionData>(initialSessionValue);

  const updateSession = async (session: SessionData) => {
    setSession(session);

    // Async storage
    localStorage.setItem('sessionData', JSON.stringify(session));

    // Set axios default headers
    axios.defaults.headers['Authorization'] = `Bearer ${session.token}`;
    axios.defaults.headers['UserId'] = session.userId;
  };

  const destroySession = () => {
    const newSessionValues = {
      ...initialSessionValue,
      languageCode: session.languageCode || 'en',
    };

    setSession(newSessionValues);

    localStorage.setItem('sessionData', JSON.stringify({ newSessionValues }));

    axios.defaults.headers['Authorization'] = '';
    axios.defaults.headers['UserId'] = 0;
  };

  return (
    <SessionContext.Provider value={{ session, updateSession, destroySession }}>
      {props.children}
    </SessionContext.Provider>
  );
};
