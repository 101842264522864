import React from 'react';
import styled from 'styled-components';

const FlagContainer = styled.div({
  padding: 30,
  borderBottom: '1px solid #EEE',
  textAlign: 'center' as 'center',
});

const Flag = (props: { country: string; onClick: () => void }) => {
  return (
    <a
      href="#"
      onClick={props.onClick}
      style={{ marginLeft: 3, marginRight: 3 }}
    >
      <img
        src={`flags/${props.country.toUpperCase()}@3x.png`}
        style={{ width: 28 }}
        alt={props.country}
      />
    </a>
  );
};

export { FlagContainer, Flag };
