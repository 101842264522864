export interface CommonStyledProps {
  // Paddings
  pV?: number;
  pH?: number;

  // Margins
  mV?: number;
  mH?: number;

  // For absolute positions
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;

  // Height & width
  h?: number;
  w?: number;

  // Flex direction
  horizontal?: boolean;

  // Flex
  flex?: boolean;

  // Center
  centerH?: boolean;
  centerV?: boolean;

  // Background
  bg?: string;
}

export const commonStyledProps = (props: CommonStyledProps): any => ({
  display: 'flex',

  // Paddings
  paddingTop: props.pV || 0,
  paddingBottom: props.pV || 0,
  paddingLeft: props.pH || 0,
  paddingRight: props.pH || 0,

  // Margin
  marginTop: props.mV || 0,
  marginBottom: props.mV || 0,
  marginLeft: props.mH || 0,
  marginRight: props.mH || 0,

  // Position
  top: props.top || 0,
  right: props.right || 0,
  bottom: props.bottom || 0,
  left: props.left || 0,

  // Height & Width
  ...(props.h && { height: props.h }),
  ...(props.w && { width: props.w }),

  // Flex direction
  flexDirection: props.horizontal ? 'row' : 'column',

  // Flex
  ...(props.flex && { flex: 1 }),

  // Center
  ...(props.centerH && !props.horizontal && { alignItems: 'center' }),
  ...(props.centerV && !props.horizontal && { justifyContent: 'center' }),
  ...(props.centerH && props.horizontal && { justifyContent: 'center' }),
  ...(props.centerV && props.horizontal && { alignItems: 'center' }),

  // Background
  ...(props.bg && { backgroundColor: props.bg }),
});
