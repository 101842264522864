import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const SidebarButton = (props: {
  title: string;
  active?: boolean;
  path?: string;
  onClick?: () => void;
}) => {
  const path = props.path || '/';
  return (
    <SidebarButtonStyle active={props.active} to={path} onClick={props.onClick}>
      {props.title}
    </SidebarButtonStyle>
  );
};

const SidebarButtonStyle = styled(Link)<{ active?: boolean }>((props) => ({
  padding: 15,
  borderBottom: '1px solid #EEE',
  transition: '0.3s',
  color: '#003044',
  fontWeight: props.active ? 'bold' : 'normal',

  '&:hover': {
    backgroundColor: '#F8F8F8',
    color: '#003044',
  },

  '&:last-child': {
    borderBottom: 'none',
  },
}));

export { SidebarButton };
