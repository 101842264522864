import React, { useState, MouseEvent } from 'react';

const EditableValue = (props: {
  text: string;
  onSave: (text: string) => void;
  onClick?: () => void;
  enableEditButton?: boolean;
  icon?: React.ReactNode;
}) => {
  const [editMode, setEditMode] = useState(false);
  const [text, setText] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const onEditClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setEditMode(true);
    setText(props.text);
  };

  const onSaveClick = async () => {
    setIsSaving(true);
    await props.onSave(text);
    setEditMode(false);
    setIsSaving(false);
  };

  if (editMode) {
    return (
      <span>
        <input
          type="text"
          value={text}
          style={titleInputStyle}
          onChange={e => setText(e.target.value)}
        />
        <a onClick={onSaveClick}>{isSaving ? 'Saving..' : 'Save'}</a>
      </span>
    );
  }
  return (
    <span
      onClick={onClick}
      style={{ cursor: props.onClick ? 'pointer' : undefined }}
    >
      {props.icon ? props.icon : null}
      {props.text}
      {props.enableEditButton ? (
        <a onClick={onEditClick}>
          <span
            className="fa fa-pencil"
            style={{ marginLeft: 6, fontSize: 12 }}
          />
        </a>
      ) : null}
    </span>
  );
};

const titleInputStyle = {
  padding: 4,
  fontSize: 14,
  borderRadius: 5,
  border: '1px solid #DDD',
  marginRight: 4,
};

export { EditableValue };
