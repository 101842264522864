import React, { useState, MouseEvent } from 'react';
import { Button } from '../Button/Button';
import * as L from '../styled/layout';

const EditableTitle = (props: {
  text: string;
  preset: 'h1' | 'h2' | 'h3';
  onSave: (text: string) => void;
  onClick?: () => void;
  enableEditButton?: boolean;
  icon?: React.ReactNode;
}) => {
  const [editMode, setEditMode] = useState(false);
  const [text, setText] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const onEditClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setEditMode(true);
    setText(props.text);
  };

  const onSaveClick = async () => {
    setIsSaving(true);
    await props.onSave(text);
    setEditMode(false);
    setIsSaving(false);
  };

  if (editMode) {
    return (
      <L.FlexDiv horizontal>
        <input
          type="text"
          value={text}
          onChange={e => setText(e.target.value)}
          style={titleInputStyle}
        />
        <div>
          <Button
            title={isSaving ? 'Saving..' : 'Save'}
            onClick={onSaveClick}
          />
        </div>
      </L.FlexDiv>
    );
  }
  return (
    <props.preset
      onClick={onClick}
      style={{ cursor: props.onClick ? 'pointer' : undefined }}
    >
      {props.icon ? props.icon : null}
      {props.text}
      {props.enableEditButton ? (
        <a onClick={onEditClick}>
          <span
            className="fa fa-pencil"
            style={{ marginLeft: 6, fontSize: 14 }}
          />
        </a>
      ) : null}
    </props.preset>
  );
};

const titleInputStyle = {
  padding: 10,
  fontSize: 22,
  fontWeight: 'bold' as 'bold',
  borderRadius: 5,
  border: '1px solid #DDD',
  width: '100%',
  marginRight: 10,
};

export { EditableTitle };
