import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import './index.css';
import App from './App';
import { SessionContextProvider } from './context/SessionContextProvider';
import { LanguageContextProvider } from './context/LangaugeContextProvider';
const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://tool.wbltraining.eu/api'
    : 'http://localhost:8080/api';

ReactDOM.render(
  <React.StrictMode>
    <SessionContextProvider>
      <LanguageContextProvider>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <Router>
            <App />
          </Router>
        </ReactQueryCacheProvider>
      </LanguageContextProvider>
    </SessionContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
