import { useEffect, useState } from 'react';
import { SessionData } from '../context/SessionContextProvider';
import { useSession } from './useSession';

export const useInitSession = () => {
  const [sessionLoading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  const { updateSession } = useSession();

  const init = async () => {
    let sessionDataRaw = await localStorage.getItem('sessionData');

    // No session in async storage
    if (!sessionDataRaw) {
      setLoading(false);
      return;
    }

    // Missing data in session async storage
    const sessionData: SessionData = JSON.parse(sessionDataRaw);
    const missingSessionData =
      !sessionData ||
      !sessionData.username ||
      !sessionData.token ||
      !sessionData.userId;

    if (missingSessionData) {
      setLoading(false);
      return;
    }

    // Logged in
    setLoggedIn(true);
    updateSession(sessionData);

    // Stop loading
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return { sessionLoading, loggedIn };
};
