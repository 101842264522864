import React, { useState } from 'react';
import { LessonSectionProp, MyFile } from '../../types';
import { Button } from '../Button/Button';
import Modal from 'react-modal';
import { useMutation, useQueryCache } from 'react-query';
import { API_URL } from '../../index';
import { EditableRichText } from '../MyEditor/EditableRichText';
import { EditableTitle } from '../MyEditor/EditableTitle';
import axios from 'axios';

const LessonSection = (props: {
  data: LessonSectionProp;
  showEditButton?: boolean;
  files?: MyFile[];
}) => {
  const queryCache = useQueryCache();
  const { data } = props;
  const [expanded, setExpanded] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [updateLessonSection] = useMutation(
    (params: { title?: string; text?: string }) => {
      const reqData: { name?: string; text?: string } = {};

      if (params.title) {
        reqData.name = params.title;
      }

      if (params.text) {
        reqData.text = params.text;
      }

      return axios
        .put(`${API_URL}/lesson/section/${data.id}`, reqData)
        .then(res => res.data);
    },
  );

  const [deleteLessonSection] = useMutation(() =>
    axios.delete(`${API_URL}/lesson/section/${data.id}`).then(res => res.data),
  );

  const onSaveClick = async (params: { title?: string; text?: string }) => {
    await updateLessonSection(params);
    await queryCache.invalidateQueries(`lesson-data-${data.lessonId}`);
  };

  const onDeleteConfirm = async () => {
    await deleteLessonSection();
    await queryCache.invalidateQueries(`lesson-data-${data.lessonId}`);
    setShowDeleteConfirm(false);
  };

  return (
    <div>
      <EditableTitle
        text={data.name}
        preset="h2"
        onSave={title => onSaveClick({ title: title })}
        onClick={() => setExpanded(!expanded)}
        icon={
          <span
            className={expanded ? 'fa fa-caret-down' : 'fa fa-caret-right'}
            style={{ fontSize: 18, marginRight: 10 }}
          />
        }
        enableEditButton={props.showEditButton}
      />

      {expanded ? (
        <div style={{ marginBottom: 40 }}>
          <EditableRichText
            html={data.text}
            enableEditButton={props.showEditButton}
            extraEditModeControlButtons={
              <div
                style={{ textAlign: 'center', padding: 20 }}
                onClick={() => setShowDeleteConfirm(true)}
              >
                <a style={{ color: '#e74c3c' }}>Delete section</a>
              </div>
            }
            onSave={text => onSaveClick({ text })}
            files={props.files}
          />
        </div>
      ) : null}

      <Modal
        isOpen={showDeleteConfirm}
        onRequestClose={() => setShowDeleteConfirm(false)}
        contentLabel="Example Modal"
        style={modalStyle}
      >
        <div style={{ textAlign: 'center' }}>
          <h2>Delete this lesson section?</h2>
          <div style={{ marginBottom: 24 }}>This it not revertable!</div>

          <Button
            title="Yes, delete"
            backgroundColor="#e74c3c"
            onClick={onDeleteConfirm}
          />
          <div
            style={{
              textAlign: 'center',
              marginTop: 12,
              color: '#999',
              cursor: 'pointer',
            }}
            onClick={() => setShowDeleteConfirm(false)}
          >
            Cancel
          </div>
        </div>
      </Modal>
    </div>
  );
};

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 3px #DDD',
    width: 450,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999,
  },
};

export { LessonSection };
