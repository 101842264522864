import React from 'react';
import * as L from '../styled/layout';
import { useWindowSize } from '../../hooks/useWindowSize';
import { mobileBreakPoint } from '../../globals';
import { MainMenuContent } from './MainMenuContent';
import { useUIStore } from '../../hooks/useUIStore';

const Sidebar = () => {
  const { width: windowWidth } = useWindowSize();
  const desktopView = windowWidth >= mobileBreakPoint;
  const { showMenu } = useUIStore();

  if (!desktopView && !showMenu) {
    return null;
  }

  if (!desktopView && showMenu) {
    return (
      <L.Absolute pV={20} pH={20}>
        <MainMenuContent showLogoutButton={true} />
      </L.Absolute>
    );
  }

  return (
    <>
      <L.FlexDiv w={320} mH={30} mV={30}>
        <MainMenuContent />
      </L.FlexDiv>
    </>
  );
};

export { Sidebar };
