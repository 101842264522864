import React from 'react';
import * as L from '../styled/layout';
import { headerHeight } from '../../globals';
import { ContentCard } from '../Content/ContentCard';
import { SupportSidebar } from './SupportSidebar';
import { ContentHeader } from '../Content/ContentHeader';
import { SupportFAQItem } from './SupportFAQItem';

const SupportScreen = () => {
  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <SupportSidebar />
      <ContentCard>
        <ContentHeader>Frequently asked questions</ContentHeader>
        <div style={{ padding: 30, paddingTop: 0 }}>
          {/*<SupportFAQItem*/}
          {/*  question="Some frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Other frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Third frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Another frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Question users keep asking?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Other frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Some frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Other frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Some frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Other frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Third frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Another frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Question users keep asking?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Other frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Some frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
          {/*<SupportFAQItem*/}
          {/*  question="Other frequently asked question?"*/}
          {/*  answer="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto at doloribus ea optio quis recusandae. Asperiores distinctio eligendi laudantium nesciunt nisi numquam porro, quas rerum soluta veniam. Eveniet, maiores, minima!"*/}
          {/*/>*/}
        </div>
      </ContentCard>
    </L.Absolute>
  );
};

export { SupportScreen };
