import React from 'react';

const DEFAULT_SPINNER_SIZE = 16;
const SVG_MAP = {
  blue: '/spinner.svg',
  green: '/spinner-green.svg',
  white: '/spinner-white.svg',
};

const Spinner = (props: {
  size?: number;
  color?: 'blue' | 'green' | 'white';
  marginLeft?: number;
  marginRight?: number;
}) => {
  const size = props.size || DEFAULT_SPINNER_SIZE;
  const color = props.color || 'blue';
  const svgFile = SVG_MAP[color];
  const marginLeft = props.marginLeft || 0;
  const marginRight = props.marginRight || 0;
  const style = { height: size, width: size, marginLeft, marginRight };

  return <img src={svgFile} style={style} alt="Loading.." title="Loading.." />;
};

export { Spinner };
