import React from 'react';

const FormSelect = (
  props: React.SelectHTMLAttributes<HTMLSelectElement> & {
    label?: string;
  },
) => {
  const { label } = props;

  const propsCleaned = { ...props };

  if (propsCleaned.style) {
    delete propsCleaned.style;
  }

  return (
    <div style={containerStyle}>
      {label ? <div style={labelStyle}>{label}</div> : null}
      <select
        style={{
          ...inputStyle,
          ...props.style,
        }}
        spellCheck={false}
        {...propsCleaned}
      >
        {props.children}
      </select>
    </div>
  );
};

const containerStyle = {
  marginBottom: 20,
};

const labelStyle = {
  fontSize: 16,
  color: '#B8B8B8',
  marginBottom: 10,
};

const inputStyle = {
  display: 'block',
  width: '100%',
  padding: 10,
  paddingLeft: 15,
  paddingRight: 15,
  fontSize: 16,
  lineHeight: 1.5,
  outline: 0,
  border: '1px solid #E5E5E5',
  backgroundColor: '#F8F8F8',
  borderRadius: 4,
  color: '#666',
  transition: '0.3s',
};

export { FormSelect };
