import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  brandBlueColor,
  brandDarkBlueColor,
  brandGreenColor,
  brandOtherGreenColor,
  TINYMCE_API_KEY,
} from '../../globals';
import HTMLReactParser from 'html-react-parser';
import { Button } from '../Button/Button';
import Modal from 'react-modal';
import { MyFile } from '../../types';
import Lightbox from 'react-image-lightbox';

const EditableRichText = (props: {
  html: string;
  enableEditButton?: boolean;
  extraEditModeControlButtons?: React.ReactNode;
  onSave: (html: string) => void;
  files?: MyFile[];
}) => {
  const { enableEditButton } = props;
  const [html, setHtml] = useState(props.html);
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const imagePickerCallback = useRef<Function>();
  const [lightboxImage, setLightboxImage] = useState<string | null>(null);

  const onEditClick = () => {
    setHtml(props.html);
    setEditMode(true);
  };

  const onSaveClick = async () => {
    setIsSaving(true);
    await props.onSave(html);
    setIsSaving(false);
    setEditMode(false);
  };

  const onImageUploadModalClose = () => {
    setShowImageModal(false);
  };

  const onImagePicked = (file: MyFile) => {
    if (imagePickerCallback.current) {
      imagePickerCallback.current(file.url, { alt: file.name });
      setShowImageModal(false);
    }
  };

  const color_map = [
    brandGreenColor,
    'Green',
    brandBlueColor,
    'Blue',
    brandDarkBlueColor,
    'Dark blue',
  ];

  if (editMode && enableEditButton) {
    return (
      <div>
        <Editor
          initialValue={html}
          onEditorChange={(content) => setHtml(content)}
          apiKey={TINYMCE_API_KEY}
          init={{
            height: 500,
            menubar: false,
            color_map,
            image_advtab: true,
            file_picker_callback: (callback, value, meta) => {
              setShowImageModal(true);
              imagePickerCallback.current = callback;
            },
            file_picker_types: 'image',

            plugins: [
              'link  image advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
              'table media',
            ],
            toolbar:
              'formatselect | bold italic forecolor backcolor | alignleft  aligncenter alignright  alignjustify | \
              bullist numlist | image link media | table tabledelete | removeformat',
          }}
        />

        <div style={{ marginTop: 20 }}>
          <Button
            title={isSaving ? 'Saving..' : 'Save'}
            onClick={onSaveClick}
          />

          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <a style={{ cursor: 'pointer' }} onClick={() => setEditMode(false)}>
              Cancel
            </a>
          </div>

          {props.extraEditModeControlButtons
            ? props.extraEditModeControlButtons
            : null}
        </div>

        {showImageModal && (
          <Modal
            isOpen={showImageModal}
            onRequestClose={onImageUploadModalClose}
            contentLabel="Example Modal"
            style={modalStyle}
          >
            <div
              style={{ overflow: 'hidden', overflowY: 'scroll', height: 300 }}
            >
              {!props.files || !props.files.length ? (
                <div>
                  Please upload images first!{' '}
                  <a onClick={() => setShowImageModal(false)}>Close</a>
                </div>
              ) : null}
              {props.files
                ? props.files
                    .filter((file) => file.type.includes('image/'))
                    .map((file, index) => (
                      <div
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: 10,
                        }}
                        key={file.id}
                        onClick={() => onImagePicked(file)}
                      >
                        <div
                          style={{
                            width: 100,
                            height: 75,
                            overflow: 'hidden',
                            position: 'relative',
                          }}
                        >
                          <img
                            src={file.url}
                            style={{
                              width: 100,
                              position: 'absolute',
                              top: -9999,
                              left: -9999,
                              right: -9999,
                              bottom: -9999,
                              margin: 'auto',
                            }}
                            alt={file.name}
                          />
                        </div>
                        <div>{file.name}</div>
                      </div>
                    ))
                : null}
            </div>
          </Modal>
        )}
      </div>
    );
  }

  return (
    <div className="full-width-images">
      {enableEditButton && (
        <div style={{ textAlign: 'right' }}>
          <a onClick={onEditClick}>
            <span className="fa fa-pencil" />
          </a>
        </div>
      )}
      {HTMLReactParser(props.html, {
        replace: (domNode) => {
          if (domNode.name === 'img') {
            return (
              <a
                style={{
                  cursor: 'pointer',
                  display: 'block',
                  textAlign: 'center',
                }}
                onClick={() => setLightboxImage(domNode.attribs?.src || null)}
              >
                <img
                  src={domNode.attribs?.src || ''}
                  alt={domNode.attribs?.alt || ''}
                  width={domNode.attribs?.width || '100%'}
                  height={domNode.attribs?.height || 'auto'}
                />
              </a>
            );
          }

          if (domNode.name === 'iframe') {
            return (
              <div style={{ textAlign: 'center' }}>
                <iframe
                  src={domNode.attribs?.src || ''}
                  width={domNode.attribs?.width || '100%'}
                  height={domNode.attribs?.height || 'auto'}
                />
              </div>
            );
          }
        },
      })}

      {lightboxImage ? (
        <Lightbox
          mainSrc={lightboxImage}
          onCloseRequest={() => setLightboxImage(null)}
        />
      ) : null}

      <div className="clear" />
    </div>
  );
};

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 3px #DDD',
    width: 450,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 25000,
  },
};

export { EditableRichText };
