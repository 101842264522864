import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import * as L from '../styled/layout';
import {
  brandBlueColor,
  brandDarkBlueColor,
  headerHeight,
} from '../../globals';
import { Sidebar } from '../Sidebar/Sidebar';
import { ContentCard } from '../Content/ContentCard';
import { ContentHeader } from '../Content/ContentHeader';
import { ContentFooter } from '../Content/ContentFooter';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { Button } from '../Button/Button';
import { LessonFullData } from '../../types';
import { API_URL } from '../../index';
import { LessonSection } from './LessonSection';
import { List } from '../List/List';
import { FileUploadWithQueue } from '../File/FileUploadWithQueue';
import { File } from '../File/File';
import { EditableTitle } from '../MyEditor/EditableTitle';
import { AdminBar } from '../common/AdminBar';
import { EditableRichText } from '../MyEditor/EditableRichText';
import axios from 'axios';
import { EditableValue } from '../MyEditor/EditableValue';
import { useLanguage } from '../../language/useLanguage';
import { useProgressData } from '../../hooks/useProgressData';
import { useProgressMutation } from '../../hooks/useProgressMutation';
import { useSession } from '../../hooks/useSession';

Modal.setAppElement('#root');

const LessonScreen = () => {
  const history = useHistory();
  const queryCache = useQueryCache();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { session } = useSession();
  const [showAddNewLessonSectionModal, setShowAddNewLessonSectionModal] =
    useState(false);
  const [newLessonSectionName, setNewLessonSectionName] = useState('');
  const [showMarkAsReadModal, setMarkAsReadModal] = useState(false);
  const [adminModeEnabled, setAdminModeEnabled] = useState(false);
  const routeParams = useParams<{ id: string }>();
  const id = Number(routeParams.id);
  const {
    data: lessonData,
    refetch,
    isLoading,
  } = useQuery<LessonFullData>(`lesson-data-${id}`, () =>
    axios.get(`${API_URL}/lesson/${id}`).then((res) => res.data),
  );
  const { data: progressData } = useProgressData();
  const completedLessons: number[] = progressData?.lessonsCompleted || [];

  const { t } = useLanguage();

  type SavePayload = {
    name?: string;
    introText?: string;
    exerciseText?: string;
    footNoteText?: string;
    readTime?: number;
    text?: string;
  };

  const [saveLesson] = useMutation((params: SavePayload) => {
    return axios.put(`${API_URL}/lesson/${id}`, params).then((res) => res.data);
  });

  const [deleteLesson] = useMutation(() =>
    axios.delete(`${API_URL}/lesson/${id}`).then((res) => res.data),
  );

  const [updateProgress] = useProgressMutation();

  const [addLessonSection] = useMutation(() =>
    axios
      .post(`${API_URL}/lesson/section`, {
        name: newLessonSectionName,
        lessonId: id,
      })
      .then((res) => res.data),
  );

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [id]);

  const onSaveClick = async (params: SavePayload) => {
    await saveLesson(params);
    await refetch();
    if (lessonData) {
      await queryCache.invalidateQueries(`module-${lessonData.data.moduleId}`);
    }
  };

  const onDeleteConfirm = async () => {
    await deleteLesson();
    if (lessonData) {
      await queryCache.invalidateQueries(`module-${lessonData.data.moduleId}`);
      history.push(`/module/${lessonData?.data.moduleId}`);
    }
  };

  const onLessonSectionAdd = async () => {
    await addLessonSection();
    await refetch();
    setShowAddNewLessonSectionModal(false);
  };

  const onNextClick = () => {
    if (lessonData) {
      const read = completedLessons.includes(lessonData.data.id);
      if (read || session.adminLevel) {
        onGoToNextLesson();
      } else {
        setMarkAsReadModal(true);
      }
    }
  };

  const onMarkAsRead = async () => {
    if (lessonData) {
      updateProgress({ lessonId: lessonData?.data.id, status: true }).then(
        () => {
          queryCache.invalidateQueries('progress-data');
        },
      );
    }
    setMarkAsReadModal(false);
    onGoToNextLesson();
  };

  const onGoToNextLesson = () => {
    setMarkAsReadModal(false);
    if (lessonData && lessonData.nextLessonData) {
      history.push(`/lesson/${lessonData.nextLessonData.id}`);
    } else {
      history.push(`/module/${lessonData?.moduleData.id}`);
    }
  };

  return (
    <div style={containerStyle} ref={containerRef}>
      <Sidebar />
      <ContentCard>
        <ContentHeader>
          <L.FlexDiv horizontal>
            <L.FlexDiv flex>
              <Link to={`/module/${lessonData?.moduleData.id}`}>
                {lessonData?.moduleData.name} &gt;{' '}
                <b>{lessonData?.data.name}</b>
              </Link>
            </L.FlexDiv>
            <div>
              {lessonData?.currentLessonNumber}/{lessonData?.totalCount}
            </div>
          </L.FlexDiv>
          <L.Spacer top={12}>
            <ProgressBar
              percent={
                ((lessonData?.currentLessonNumber || 0) /
                  (lessonData?.totalCount || 1)) *
                100
              }
            />
          </L.Spacer>
        </ContentHeader>

        <div style={{ padding: 30, paddingTop: 0 }}>
          <AdminBar
            enabled={adminModeEnabled}
            onModeChange={() => setAdminModeEnabled(!adminModeEnabled)}
          />
          <EditableTitle
            enableEditButton={adminModeEnabled}
            text={lessonData?.data.name || ''}
            preset="h1"
            onSave={(name) => onSaveClick({ name })}
          />

          {adminModeEnabled || lessonData?.data.introText ? (
            <div
              style={{
                backgroundColor: '#EAFAFD',
                padding: 10,
                borderRadius: 4,
                marginBottom: 20,
                border: '1px solid #C2EEF7',
              }}
            >
              <h2 style={{ marginTop: 0 }}>{t('introduction')}</h2>

              <EditableRichText
                html={lessonData?.data.introText || ''}
                onSave={(introText) => onSaveClick({ introText })}
                enableEditButton={adminModeEnabled}
                files={lessonData?.files}
              />
            </div>
          ) : null}
          <EditableRichText
            html={lessonData?.data.text || ''}
            onSave={(text) => onSaveClick({ text })}
            enableEditButton={adminModeEnabled}
            files={lessonData?.files}
          />
          {lessonData?.sections.map((section) => (
            <LessonSection
              data={section}
              key={section.id}
              showEditButton={adminModeEnabled}
              files={lessonData?.files}
            />
          ))}
          {adminModeEnabled ? (
            <a
              style={{
                color: brandBlueColor,
                marginTop: 20,
                marginBottom: 20,
                display: 'block',
              }}
              onClick={() => {
                setShowAddNewLessonSectionModal(true);
                setNewLessonSectionName('');
              }}
            >
              <span className="fa fa-plus" style={{ marginRight: 5 }} />
              Add section
            </a>
          ) : null}
          {adminModeEnabled || lessonData?.data.exerciseText ? (
            <div
              style={{
                backgroundColor: '#EAFAFD',
                padding: 10,
                borderRadius: 4,
                marginBottom: 20,
                marginTop: 20,
                border: '1px solid #C2EEF7',
              }}
            >
              <h2 style={{ marginTop: 0 }}>{t('exercise')}</h2>

              <EditableRichText
                html={lessonData?.data.exerciseText || ''}
                onSave={(exerciseText) => onSaveClick({ exerciseText })}
                enableEditButton={adminModeEnabled}
                files={lessonData?.files}
              />
            </div>
          ) : null}

          {adminModeEnabled || lessonData?.data.footNoteText ? (
            <div
              style={{
                backgroundColor: '#F8F8F8',
                padding: 10,
                borderRadius: 4,
                marginBottom: 20,
                marginTop: 20,
                border: '1px solid #EEE',
              }}
            >
              <h2 style={{ marginTop: 0 }}>{t('citations')}</h2>

              <EditableRichText
                html={lessonData?.data.footNoteText || ''}
                onSave={(footNoteText) => onSaveClick({ footNoteText })}
                enableEditButton={adminModeEnabled}
                files={lessonData?.files}
              />
            </div>
          ) : null}

          {lessonData?.files.filter(
            (file) => file.showInList || adminModeEnabled,
          ).length ? (
            <div>
              <h3>{t('files')}</h3>
              <List>
                {lessonData?.files
                  .filter((file) => file.showInList || adminModeEnabled)
                  .map((file, index) => (
                    <File
                      key={file.id}
                      data={file}
                      showFileAdminButtons={adminModeEnabled}
                      first={index === 0}
                      last={index === lessonData.files.length - 1}
                      onFileUpdated={() =>
                        queryCache.invalidateQueries(`lesson-data-${id}`)
                      }
                    />
                  )) || null}
              </List>
            </div>
          ) : null}
          {adminModeEnabled ? (
            <div style={{ paddingTop: 10 }}>
              <FileUploadWithQueue
                contentModule="lesson"
                contentId={lessonData?.data.id || 0}
                onUploadFinished={() =>
                  queryCache.invalidateQueries(`lesson-data-${id}`)
                }
              />
              <div style={{ paddingTop: 10, color: '#BBB' }}>
                Please do not upload any private/internal files here!
              </div>
            </div>
          ) : null}

          {adminModeEnabled && (
            <div style={{ marginTop: 20, fontWeight: 'bold' }}>
              Read time (minutes):{' '}
              <EditableValue
                text={
                  lessonData?.data.readTime
                    ? lessonData?.data.readTime.toString()
                    : '0'
                }
                onSave={(readTime) =>
                  onSaveClick({ readTime: readTime ? Number(readTime) : 0 })
                }
                enableEditButton={adminModeEnabled}
              />
            </div>
          )}
        </div>

        {adminModeEnabled && (
          <div style={{ marginTop: 20 }}>
            <div
              style={{ textAlign: 'center', padding: 20 }}
              onClick={() => setShowDeleteConfirm(true)}
            >
              <a style={{ color: '#e74c3c' }}>Delete lesson</a>
            </div>
          </div>
        )}

        <ContentFooter>
          <L.FlexDiv horizontal>
            <L.FlexDiv horizontal centerV>
              {lessonData?.previousLessonData ? (
                <Link
                  to={`/lesson/${lessonData.previousLessonData.id}`}
                  style={{ color: brandDarkBlueColor }}
                >
                  <span
                    className="fa fa-chevron-left"
                    style={{ marginRight: 5 }}
                  />
                  {t('previousLesson')}
                </Link>
              ) : null}
            </L.FlexDiv>

            <L.FlexDiv flex />
            <div style={{ width: 200 }}>
              {lessonData ? (
                <Button
                  title={t(
                    lessonData?.nextLessonData
                      ? 'nextLesson'
                      : 'goBackToModule',
                  )}
                  onClick={onNextClick}
                />
              ) : null}
            </div>
          </L.FlexDiv>
        </ContentFooter>

        <Modal
          isOpen={showDeleteConfirm}
          onRequestClose={() => setShowDeleteConfirm(false)}
          contentLabel="Example Modal"
          style={modalStyle}
        >
          <div style={{ textAlign: 'center' }}>
            <h2>Delete this lesson?</h2>
            <div style={{ marginBottom: 24 }}>This it not revertable!</div>

            <Button
              title="Yes, delete"
              backgroundColor="#e74c3c"
              onClick={onDeleteConfirm}
            />
            <div
              style={{
                textAlign: 'center',
                marginTop: 12,
                color: '#999',
                cursor: 'pointer',
              }}
              onClick={() => setShowDeleteConfirm(false)}
            >
              Cancel
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={showMarkAsReadModal}
          onRequestClose={() => setMarkAsReadModal(false)}
          contentLabel="Example Modal"
          style={modalStyle}
        >
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ marginBottom: 24 }}>{t('markThisLessonAsRead')}</h2>
            <Button title={t('yes')} onClick={onMarkAsRead} />
            <div
              style={{
                textAlign: 'center',
                marginTop: 12,
                color: '#999',
                cursor: 'pointer',
              }}
              onClick={onGoToNextLesson}
            >
              {t('no')}
            </div>
          </div>
        </Modal>

        {showAddNewLessonSectionModal && (
          <Modal
            isOpen={true}
            onRequestClose={() => setShowAddNewLessonSectionModal(false)}
            contentLabel="Example Modal"
            style={modalStyle}
          >
            <div style={{ fontWeight: 'bold' }}>Name of lesson section</div>
            <input
              style={titleInputStyle}
              value={newLessonSectionName}
              onChange={(e) => setNewLessonSectionName(e.target.value)}
            />
            <Button title="Add" onClick={onLessonSectionAdd} />
          </Modal>
        )}
      </ContentCard>
    </div>
  );
};

const containerStyle: React.CSSProperties = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  top: headerHeight,
  right: 0,
  bottom: 0,
  left: 0,
  overflowY: 'scroll',
};

const titleInputStyle = {
  padding: 10,
  fontSize: '2em',
  fontWeight: 'bold' as 'bold',
  marginTop: 15,
  marginBottom: 15,
  borderRadius: 5,
  border: '1px solid #DDD',
  width: '100%',
};

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 3px #DDD',
    width: 450,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999,
  },
};

export { LessonScreen };
