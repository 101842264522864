import React from 'react';
import { TakeTestAnswer, TakeTestQuestionProp } from '../../types';
import { List } from '../List/List';
import { ListItem } from '../List/ListItem';
import {
  SortableContainer,
  SortableElement,
  SortEnd,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { EditableRichText } from '../MyEditor/EditableRichText';
import { useLanguage } from '../../language/useLanguage';

const SortableItem = SortableElement(
  ({
    answer,
    listIndex,
    questionCount,
    onItemClick,
  }: {
    answer: TakeTestAnswer;
    listIndex: number;
    questionCount: number;
    onItemClick: (id: number, text: string) => void;
  }) => (
    <ListItem
      key={answer.id}
      first={listIndex === 0}
      last={listIndex === questionCount - 1}
      title={answer.answerText}
      bold
      icon="arrows-v"
      showCheckBox={false}
      // initialCheckboxStatus={answer.correct}
      onClick={() => onItemClick(answer.id, answer.answerText)}
    />
  ),
);

const SortableList = SortableContainer(
  ({
    answers,
    onItemClick,
  }: {
    answers: TakeTestAnswer[];
    onItemClick: (id: number, text: string) => void;
  }) => {
    return (
      <div>
        {answers.map((answer, index) => (
          <SortableItem
            key={`item-${answer.id}`}
            answer={answer}
            index={index}
            listIndex={index}
            questionCount={answers.length}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    );
  },
);

const TakeTestQuestion = (props: {
  question: TakeTestQuestionProp;
  selectedAnswers: number[];
  onAnswerUpdate: (answers: number[]) => void;
}) => {
  const { question, selectedAnswers } = props;
  const { t } = useLanguage();

  const sortedAnswers: TakeTestAnswer[] = [];

  if (question.questionType === 'correctOrder') {
    selectedAnswers.forEach((answerId) => {
      const findQuestion = question.answers.find((a) => a.id === answerId);
      if (findQuestion) {
        sortedAnswers.push(findQuestion);
      }
    });
  }

  const isAnswerChecked = (answerId: number) => {
    return selectedAnswers.includes(answerId);
  };

  const onCorrectAnswerUpdate = (id: number, checked: boolean) => {
    const newSelectedAnswers = checked
      ? [...selectedAnswers, id]
      : selectedAnswers.filter((a) => a !== id);
    props.onAnswerUpdate(newSelectedAnswers);
  };

  const onOrderChanged = async (e: SortEnd) => {
    const { oldIndex, newIndex } = e;
    const resortedAnswers = arrayMove(sortedAnswers, oldIndex, newIndex);
    props.onAnswerUpdate(resortedAnswers.map((a) => a.id));
  };

  return (
    <div>
      <h1>{question.questionText}</h1>

      <EditableRichText
        html={question.description || ''}
        onSave={(html: string) => {
          console.log(html);
        }}
        enableEditButton={false}
        files={[]}
      />

      <div style={{ paddingBottom: 12, fontWeight: 'bold' }}>
        {question.questionType === 'correctOrder' ? (
          <div>{t('orderAnswersCorrectly')}</div>
        ) : null}
        {question.questionType === 'singleAnswer' ? (
          <div>{t('chooseCorrectAnswer')}</div>
        ) : null}
        {question.questionType === 'multipleAnswer' ? (
          <div>{t('chooseMultipleAnswers')}</div>
        ) : null}
      </div>

      <List>
        {question.questionType === 'correctOrder' ? (
          <SortableList
            answers={sortedAnswers}
            onSortEnd={onOrderChanged}
            onItemClick={() => {}}
            distance={5}
          />
        ) : (
          question.answers.map((answer, index) => (
            <ListItem
              key={answer.id}
              first={index === 0}
              last={index === question.answers.length - 1}
              title={answer.answerText}
              bold
              showCheckBox={true}
              initialCheckboxStatus={isAnswerChecked(answer.id)}
              checkboxStatus={isAnswerChecked(answer.id)}
              onCheckBoxChange={(checked) =>
                onCorrectAnswerUpdate(answer.id, checked)
              }
              onClick={() => {
                onCorrectAnswerUpdate(answer.id, !isAnswerChecked(answer.id));
              }}
            />
          ))
        )}
      </List>
    </div>
  );
};

export { TakeTestQuestion };
