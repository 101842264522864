import React from 'react';
import * as L from '../styled/layout';
import * as S from '../styled/styled';
import { headerHeight } from '../../globals';
import { ContentCard } from '../Content/ContentCard';
import { ContentHeader } from '../Content/ContentHeader';
import { AdminSidebar } from './AdminSidebar';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../..';
import moment from 'moment';

const AdminUsers = () => {
  const { data: userData, isLoading } = useQuery<{
    list: { id: number; created: string; name: string; email: string }[];
  }>(`user-list`, () =>
    axios.get(`${API_URL}/admin/user-list`).then((res) => res.data),
  );

  const userList = userData?.list || [];

  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <AdminSidebar />

      <ContentCard>
        <ContentHeader>Admin users</ContentHeader>
        {isLoading ? (
          <div style={{ padding: 30 }}>Loading...</div>
        ) : (
          <div style={{ padding: 30 }}>
            {userList.length ? (
              <table style={{ width: '100%' }}>
                <tr>
                  <td style={{ width: '30%' }}>Name</td>
                  <td style={{ width: '40%' }}>Email</td>
                  <td style={{ width: '30%' }}>Registered</td>
                </tr>

                {userList.map((u) => (
                  <tr key={u.id}>
                    <td>{u.name}</td>
                    <td>{u.email}</td>
                    <td>{moment(u.created).format('DD.MM.YYYY, HH:mm')}</td>
                  </tr>
                ))}
              </table>
            ) : (
              <div>No users registered</div>
            )}
          </div>
        )}
      </ContentCard>
    </L.Absolute>
  );
};

export { AdminUsers };
