import { useContext } from 'react';
import { LanguageContext } from '../context/LangaugeContextProvider';
import translations from '../translations/translations.json';

export type AvailableLanguages = keyof typeof translations;
export type AvailableLanguageKeys = keyof typeof translations.en;

export const useLanguage = () => {
  const { lang, setLang } = useContext(LanguageContext);

  const t = (key: AvailableLanguageKeys): string => {
    if (translations[lang]) {
      if (translations[lang][key]) {
        return translations[lang][key] || key;
      }
    }

    if (translations.en) {
      if (translations.en[key]) {
        return translations.en[key] || key;
      }
    }

    return key;
  };

  return {
    selectedLanguage: lang,
    changeLanguage: setLang,
    t,
  };
};
