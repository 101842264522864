import React from 'react';
import * as L from '../styled/layout';
import { headerHeight } from '../../globals';
import { ContentCard } from '../Content/ContentCard';
import { SupportSidebar } from './SupportSidebar';
import { ContentHeader } from '../Content/ContentHeader';
import { FormInput } from '../Form/FormInput';
import { Button } from '../Button/Button';
import { FormTextarea } from '../Form/FormTextarea';

const SupportContactScreen = () => {
  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <SupportSidebar />
      <ContentCard>
        <ContentHeader>Contact us</ContentHeader>
        <div style={{ padding: 30 }}>
          <FormInput label="Subject" />
          <FormTextarea label="Message" style={{ height: 200 }} />
          <Button title="Send" onClick={() => {}} />
        </div>
      </ContentCard>
    </L.Absolute>
  );
};

export { SupportContactScreen };
