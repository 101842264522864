import React, { useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { Header } from './components/Header/Header';
import { LoginScreen } from './components/Login/LoginScreen';
import { OverviewScreen } from './components/Overview/OverviewScreen';
import * as L from './components/styled/layout';
import { ModuleScreen } from './components/Module/ModuleScreen';
import { LessonScreen } from './components/Lesson/LessonScreen';
import { TestScreen } from './components/Test/TestScreen';
import { MyProfileScreen } from './components/MyProfile/MyProfileScreen';
import { SupportScreen } from './components/Support/SupportScreen';
import { SupportContactScreen } from './components/Support/SupportContactScreen';
import { SearchScreen } from './components/Search/SearchScreen';
import 'react-image-lightbox/style.css';
import 'draft-js/dist/Draft.css';
import { useInitSession } from './hooks/useInitSession';
import { GuardedRoute } from './components/common/GuardedRoute';
import { useSession } from './hooks/useSession';
import { TestQuestionScreen } from './components/Test/TestQuestionScreen';
import { SignupScreen } from './components/Login/SignupScreen';
import { useLanguageList } from './hooks/useLanguageList';
import { TakeTestScreen } from './components/Test/TakeTestScreen';
import { useLanguage } from './language/useLanguage';
import { TakeFinalTestScreen } from './components/Test/TakeFinalTestScreen';
import { useQuery } from 'react-query';
import { Module } from './types';
import axios from 'axios';
import { API_URL } from './index';
import { AdminFeedback } from './components/Admin/AdminFeedback';
import { AdminTestResults } from './components/Admin/AdminTestResults';
import { AdminCertificates } from './components/Admin/AdminCertificates';
import { AdminUsers } from './components/Admin/AdminUsers';

function App() {
  const location = useLocation();
  useLanguageList();
  const history = useHistory();
  const { sessionLoading, loggedIn } = useInitSession();
  const { session } = useSession();
  const { changeLanguage } = useLanguage();

  const { data: moduleList } = useQuery<{
    list: Module[];
  }>(
    'module-list',
    () => {
      return axios.get(`${API_URL}/module/list`).then((res) => res.data);
    },
    {
      enabled: loggedIn,
    },
  );

  useEffect(() => {
    const foundModule = moduleList?.list.find((m) => m.type === 'overview');
    if (
      !sessionLoading &&
      loggedIn &&
      (location.pathname === '/' || location.pathname === '/login') &&
      foundModule
    ) {
      history.push(`/module/${foundModule.id}`);
    }
  }, [sessionLoading, loggedIn, moduleList]);

  useEffect(() => {
    if (session && session.languageCode) {
      changeLanguage(session.languageCode);
    }
  }, [session]);

  const isAuthed = loggedIn || !!session.userId;

  const showHeaderContent = !['/', '/signup', '/forgot-password'].includes(
    location.pathname,
  );

  return (
    <L.Absolute bg="#F1F1F1">
      <Header showHeaderContent={showHeaderContent} />
      {!sessionLoading && (
        <Switch>
          <GuardedRoute isAuthed={isAuthed} path="/admin/feedback">
            <AdminFeedback />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/admin/test-results">
            <AdminTestResults />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/admin/certificates">
            <AdminCertificates />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/admin/users">
            <AdminUsers />
          </GuardedRoute>

          <GuardedRoute isAuthed={isAuthed} path="/search">
            <SearchScreen />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/contact-us">
            <SupportContactScreen />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/support">
            <SupportScreen />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/my-profile">
            <MyProfileScreen />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/final-test">
            <TakeFinalTestScreen />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/test/:id/q/:questionId">
            <TestQuestionScreen />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/test/:id">
            <TestScreen />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/take-test/:id">
            <TakeTestScreen />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/lesson/:id">
            <LessonScreen />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/module/:id">
            <ModuleScreen />
          </GuardedRoute>
          <GuardedRoute isAuthed={isAuthed} path="/overview">
            <OverviewScreen />
          </GuardedRoute>
          <Route path="/signup">
            <SignupScreen />
          </Route>
          <Route path="/">
            <LoginScreen />
          </Route>
        </Switch>
      )}
    </L.Absolute>
  );
}

export default App;
