export const headerHeight = 70;

export const brandDarkBlueColor = '#003044';
export const brandDarkBlueColorDarker = '#01202D';
export const brandDarkBlueColorLighter = '#023C54';

export const brandBlueColor = '#02829D';
export const brandBlueColorDarker = '#00728A';
export const brandBlueColorLighter = '#109CBA';

export const brandGreenColor = '#2DE484';
export const brandGreenColorDarker = '#059E4E';
export const brandGreenColorLighter = '#059E4E';

export const brandOtherGreenColor = '#09BC5E';
export const brandOtherGreenColorDarker = '#039D4C';

export const redColor = '#e74c3c';

export const TINYMCE_API_KEY =
  'p0k5jl7ud1j1bbok2vdb1aw1z9mlcwg6f2z11k7rpv43y6bn';

export const mobileBreakPoint = 1024;
