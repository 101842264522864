import React, { useState } from 'react';
import * as L from '../styled/layout';
import { brandBlueColor, headerHeight, redColor } from '../../globals';
import { Sidebar } from '../Sidebar/Sidebar';
import { ContentCard } from '../Content/ContentCard';
import { ContentHeader } from '../Content/ContentHeader';
import { List } from '../List/List';
import { ListItem } from '../List/ListItem';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { TestFullData } from '../../types';
import axios from 'axios';
import { API_URL } from '../../index';
import { AdminBar } from '../common/AdminBar';
import { EditableTitle } from '../MyEditor/EditableTitle';
import { EditableValue } from '../MyEditor/EditableValue';
import Modal from 'react-modal';
import { Button } from '../Button/Button';
import { ContentFooter } from '../Content/ContentFooter';

const TestScreen = () => {
  const history = useHistory();
  const queryCache = useQueryCache();

  const [adminModeEnabled, setAdminModeEnabled] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [
    showAddNewTestQuestionModal,
    setShowAddNewTestQuestionModal,
  ] = useState(false);
  const [newTestQuestionName, setNewTestQuestionName] = useState('');

  const routeParams = useParams<{ id: string }>();
  const id = Number(routeParams.id);
  const { data: testData, refetch } = useQuery<TestFullData>(
    `test-data-${id}`,
    () => axios.get(`${API_URL}/test/${id}`).then(res => res.data),
  );

  type SavePayload = {
    name?: string;
    questionCount?: number;
  };

  const [saveTest] = useMutation((params: SavePayload) => {
    return axios.put(`${API_URL}/test/${id}`, params).then(res => res.data);
  });

  const [deleteTest] = useMutation(() =>
    axios.delete(`${API_URL}/test/${id}`).then(res => res.data),
  );

  const [addQuestion] = useMutation(() =>
    axios
      .post(`${API_URL}/test-question/`, {
        questionText: newTestQuestionName,
        testId: id,
      })
      .then(res => res.data),
  );

  const onSaveClick = async (params: SavePayload) => {
    await saveTest(params);
    await refetch();
    if (testData) {
      await queryCache.invalidateQueries(`module-${testData.data.moduleId}`);
    }
  };

  const onAddQuestion = async () => {
    await addQuestion();
    await refetch();
    setShowAddNewTestQuestionModal(false);
  };

  const onDeleteConfirm = async () => {
    await deleteTest();
    if (testData) {
      await queryCache.invalidateQueries(`module-${testData.data.moduleId}`);
      history.push(`/module/${testData?.data.moduleId}`);
    }
  };

  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <Sidebar />
      <ContentCard>
        <ContentHeader>
          <L.FlexDiv horizontal>
            <L.FlexDiv flex>
              <Link to={`/module/${testData?.moduleData.id}`}>
                {testData?.moduleData.name} > <b>{testData?.data.name}</b>
              </Link>
            </L.FlexDiv>
          </L.FlexDiv>
        </ContentHeader>

        <div style={{ padding: 30, paddingTop: 15 }}>
          <AdminBar
            enabled={adminModeEnabled}
            onModeChange={() => setAdminModeEnabled(!adminModeEnabled)}
          />

          <EditableTitle
            enableEditButton={adminModeEnabled}
            text={testData?.data.name || ''}
            preset="h1"
            onSave={name => onSaveClick({ name })}
          />

          <div style={{ marginBottom: 24 }}>
            <div>
              Test question count:{' '}
              <b>
                <EditableValue
                  text={testData?.data.questionCount.toString() || '0'}
                  enableEditButton={adminModeEnabled}
                  onSave={questionCount =>
                    onSaveClick({ questionCount: Number(questionCount) || 0 })
                  }
                />
              </b>
            </div>
            <div>
              Total question count in pool:{' '}
              <b>{testData?.questions.length || 0}</b>
            </div>

            {(testData?.questions.length || 0) <
            (testData?.data.questionCount || 0) ? (
              <div
                style={{ color: redColor, marginTop: 12, fontWeight: 'bold' }}
              >
                Please add more questions to question pool!
              </div>
            ) : null}
          </div>

          <List>
            {testData?.questions.map((question, index) => (
              <ListItem
                key={question.id}
                first={index === 0}
                last={index === testData.questions.length - 1}
                title={question.questionText}
                icon="question-circle"
                bold
                metaIcon="file"
                metaText=""
                path={`/test/${id}/q/${question.id}`}
                initialCheckboxStatus={false}
                onCheckBoxChange={() => console.log('test')}
              />
            ))}
          </List>
          {adminModeEnabled ? (
            <div style={{ marginTop: 20 }}>
              <a
                style={{ color: brandBlueColor }}
                onClick={() => {
                  setShowAddNewTestQuestionModal(true);
                  setNewTestQuestionName('');
                }}
              >
                <span className="fa fa-plus" style={{ marginRight: 5 }} />
                Add new question
              </a>
            </div>
          ) : null}
        </div>
        {adminModeEnabled && (
          <ContentFooter>
            {adminModeEnabled && (
              <div>
                <div
                  style={{ textAlign: 'center', padding: 20 }}
                  onClick={() => setShowDeleteConfirm(true)}
                >
                  <a style={{ color: '#e74c3c' }}>Delete test</a>
                </div>
              </div>
            )}
            {/*<L.FlexDiv horizontal>*/}
            {/*  <L.FlexDiv horizontal centerV>*/}
            {/*    <Link to="#" style={{ color: brandDarkBlueColor }}>*/}
            {/*      <span*/}
            {/*        className="fa fa-chevron-left"*/}
            {/*        style={{ marginRight: 5 }}*/}
            {/*      />*/}
            {/*      Previous question*/}
            {/*    </Link>*/}
            {/*  </L.FlexDiv>*/}

            {/*  <L.FlexDiv flex />*/}
            {/*  <div style={{ width: 200 }}>*/}
            {/*    <LinkButton title="Next question" path="#" />*/}
            {/*  </div>*/}
            {/*</L.FlexDiv>*/}
          </ContentFooter>
        )}

        {showAddNewTestQuestionModal && (
          <Modal
            isOpen={true}
            onRequestClose={() => setShowAddNewTestQuestionModal(false)}
            contentLabel="Example Modal"
            style={modalStyle}
          >
            <div>
              <div style={{ fontWeight: 'bold' }}>Question</div>
              <input
                style={titleInputStyle}
                value={newTestQuestionName}
                onChange={e => setNewTestQuestionName(e.target.value)}
              />
            </div>

            <Button title="Add" onClick={onAddQuestion} />
          </Modal>
        )}

        <Modal
          isOpen={showDeleteConfirm}
          onRequestClose={() => setShowDeleteConfirm(false)}
          contentLabel="Example Modal"
          style={modalStyle}
        >
          <div style={{ textAlign: 'center' }}>
            <h2>Delete this test?</h2>
            <div style={{ marginBottom: 24 }}>This it not revertable!</div>

            <Button
              title="Yes, delete"
              backgroundColor="#e74c3c"
              onClick={onDeleteConfirm}
            />
            <div
              style={{
                textAlign: 'center',
                marginTop: 12,
                color: '#999',
                cursor: 'pointer',
              }}
              onClick={() => setShowDeleteConfirm(false)}
            >
              Cancel
            </div>
          </div>
        </Modal>
      </ContentCard>
    </L.Absolute>
  );
};

const titleInputStyle = {
  padding: 10,
  fontSize: '2em',
  fontWeight: 'bold' as 'bold',
  marginTop: 15,
  marginBottom: 15,
  borderRadius: 5,
  border: '1px solid #DDD',
  width: '100%',
};

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 3px #DDD',
    width: 450,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
};

export { TestScreen };
