import React from 'react';
import styled from 'styled-components';

const ContentFooter = (props: { children: React.ReactNode }) => {
  return <ContentFooterStyle>{props.children}</ContentFooterStyle>;
};

const ContentFooterStyle = styled.div({
  padding: 30,
  paddingTop: 15,
  paddingBottom: 15,
  color: '#B6B6B6',
  borderTop: '1px solid #EEE',
});

export { ContentFooter };
