import React from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarButton } from '../Sidebar/SidebarButton';
import * as L from '../styled/layout';
import * as S from '../styled/styled';
import { useSession } from '../../hooks/useSession';

export const AdminSidebar = () => {
  const location = useLocation();
  const { session } = useSession();

  if (!session.adminLevel) {
    return null;
  }

  return (
    <L.FlexDiv w={320} mH={30} mV={30}>
      <S.Card style={{ marginBottom: 20 }}>
        <SidebarButton
          title="Feedback"
          active={location.pathname === '/admin/feedback'}
          path="/admin/feedback"
        />
        <SidebarButton
          title="Test results"
          active={location.pathname === '/admin/test-results'}
          path="/admin/test-results"
        />
        <SidebarButton
          title="Certificates"
          active={location.pathname === '/admin/certificates'}
          path="/admin/certificates"
        />
        <SidebarButton
          title="Users"
          active={location.pathname === '/admin/users'}
          path="/admin/users"
        />
        <SidebarButton
          title="Back to course"
          active={location.pathname === '/overview'}
          path="/overview"
        />
      </S.Card>
    </L.FlexDiv>
  );
};
