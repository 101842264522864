import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../index';
import { Language } from '../types';

export const useLanguageList = () => {
  return useQuery('languageList', () =>
    axios
      .get<{
        list: Language[];
      }>(`${API_URL}/language/list`)
      .then((res) => res.data.list),
  );
};
