import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../index';
import { brandBlueColor } from '../../globals';
type MyFile = {
  blob: File;
  id: string;
  uploading: boolean;
  uploaded: boolean;
};

const FileUploadWithQueue = (props: {
  contentModule: string;
  contentId: number;
  onUploadFinished?: () => void;
}) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [uploadQueue, setUploadQueue] = useState<MyFile[]>([]);
  const dragCounter = useRef(0);
  const [dragHighlight, setDragHighlight] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const uploadQueueCount = uploadQueue.length;
  const uploadedCount = uploadQueue.filter(file => file.uploaded).length;

  const refresh = async () => {
    if (props.onUploadFinished) {
      setRefreshing(true);
      await props.onUploadFinished();
      setRefreshing(false);
    }
  };

  useEffect(() => {
    uploadNextFileInQueue();
    if (uploadQueueCount && uploadedCount === uploadQueueCount) {
      if (fileInput.current) {
        fileInput.current.value = '';
      }
      setUploadQueue([]);
      refresh();
    }
  }, [uploadQueue]);

  const uploadNextFileInQueue = () => {
    if (!uploadQueue.find(file => file.uploading)) {
      const nextFile = uploadQueue.find(file => !file.uploaded);
      if (nextFile) {
        uploadFile(nextFile);
      }
    }
  };

  const uploadFile = async (file: MyFile) => {
    // Make FormData
    const data = new FormData();
    data.append('contentModule', props.contentModule);
    data.append('contentId', props.contentId.toString());
    data.append('file', file.blob);

    // Set uploading status
    setUploadQueue(
      uploadQueue.map(queueFile => {
        if (queueFile.id === file.id) {
          return {
            ...queueFile,
            uploading: true,
          };
        }

        return queueFile;
      }),
    );

    // Upload
    await axios.request({
      url: `${API_URL}/upload`,
      data,
      method: 'post',
      responseType: 'json',
      headers: {
        'content-type': 'multipart/form-data',
      },

      // Valid upload
      validateStatus: status => {
        setUploadQueue(
          uploadQueue.map(queueFile => {
            if (queueFile.id === file.id) {
              return {
                ...queueFile,
                uploading: false,
                uploaded: true,
              };
            }

            return queueFile;
          }),
        );
        return status === 200;
      },
    });
  };

  const processFilesToQueue = (list: FileList | DataTransferItemList) => {
    const processedList: MyFile[] = [];
    const dataTransferList = list instanceof DataTransferItemList ? list : null;
    const filesList = list instanceof FileList ? list : null;

    // From drag and drop
    if (dataTransferList) {
      for (let i = 0; i < dataTransferList.length; i++) {
        const file = dataTransferList[i].getAsFile();
        if (file) {
          processedList.push({
            blob: file,
            uploaded: false,
            uploading: false,
            id: `${file.name}-${file.size}`,
          });
        }
      }
    }

    // From add file
    if (filesList) {
      for (let i = 0; i < filesList.length; i++) {
        const file = filesList.item(i);
        if (file) {
          processedList.push({
            blob: file,
            uploaded: false,
            uploading: false,
            id: `${file.name}-${file.size}`,
          });
        }
      }
    }

    setUploadQueue([...uploadQueue, ...processedList]);
  };

  const onFilesAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files) return;
    processFilesToQueue(files);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragHighlight(false);
    dragCounter.current = 0;
    processFilesToQueue(e.dataTransfer.items);
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    dragCounter.current++;
    setDragHighlight(true);
  };

  const onDragLeave = () => {
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragHighlight(false);
    }
  };

  const onChooseFileClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  return (
    <>
      <input
        id="_upload"
        ref={fileInput}
        onChange={onFilesAdd}
        type="file"
        name="uploadFiles[]"
        multiple
        style={{ display: 'none' }}
      />

      <div
        style={{
          textAlign: 'center',
          padding: 20,
          background: '#F8F8F8',
          border: dragHighlight
            ? `1px solid ${brandBlueColor}`
            : '1px solid #DDD',
          borderRadius: 4,
        }}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={e => e.preventDefault()}
      >
        {uploadQueueCount || refreshing ? (
          <div>
            {refreshing ? (
              <span>Refreshing...</span>
            ) : (
              <span>
                Uploading... {uploadedCount} of {uploadQueueCount} completed
              </span>
            )}
          </div>
        ) : (
          <>
            {dragHighlight ? (
              <div>You can drop files now</div>
            ) : (
              <div>
                Drag and drop files or{' '}
                <a onClick={onChooseFileClick}> click here to choose</a>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export { FileUploadWithQueue };
