import React from 'react';
import * as L from '../styled/layout';
import { headerHeight } from '../../globals';
import { ContentCard } from '../Content/ContentCard';
import { MyProfileSidebar } from './MyProfileSidebar';
import { ContentHeader } from '../Content/ContentHeader';
import { FormInput } from '../Form/FormInput';
import { Button } from '../Button/Button';
import { Alert } from '../Alert/Alert';

const MyProfileScreen = () => {
  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <MyProfileSidebar />
      {/*<ContentCard>*/}
      {/*  <ContentHeader>My profile</ContentHeader>*/}
      {/*  <div style={{ padding: 30, paddingTop: 0 }}>*/}
      {/*    <L.Spacer bottom={48}>*/}
      {/*      <h2>General info</h2>*/}
      {/*      <FormInput label="Email" value="john.doe@wbl.lddk.lv" />*/}
      {/*      <FormInput label="Full name" value="John Doe" />*/}
      {/*      <FormInput label="Country" value="Latvia" />*/}
      {/*      <div style={{ width: 150 }}>*/}
      {/*        <Button title="Save" onClick={() => {}} />*/}
      {/*      </div>*/}
      {/*    </L.Spacer>*/}

      {/*    <L.Spacer bottom={48}>*/}
      {/*      <h2>Change password</h2>*/}
      {/*      <FormInput label="New password" value="*******" secureText />*/}
      {/*      <FormInput label="Confirm password" value="*******" secureText />*/}
      {/*      <div style={{ width: 150 }}>*/}
      {/*        <Button title="Save" onClick={() => {}} />*/}
      {/*      </div>*/}
      {/*    </L.Spacer>*/}

      {/*    <h2>Delete account</h2>*/}
      {/*    <Alert*/}
      {/*      text="Danger zone! Deleting account will remove your account and all*/}
      {/*      course progress from our servers. This can not be undone."*/}
      {/*    />*/}

      {/*    <div style={{ width: 150, marginTop: 24 }}>*/}
      {/*      <Button*/}
      {/*        title="Delete"*/}
      {/*        onClick={() => {}}*/}
      {/*        backgroundColor="#e74c3c"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</ContentCard>*/}
    </L.Absolute>
  );
};

export { MyProfileScreen };
