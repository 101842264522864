import React from 'react';
import * as L from '../styled/layout';
import { headerHeight } from '../../globals';
import { ContentCard } from '../Content/ContentCard';
import { SearchSidebar } from './SearchSidebar';
import { ContentHeader } from '../Content/ContentHeader';
import { FormInput } from '../Form/FormInput';
import { SearchResultItem } from './SearchResultItem';

const SearchScreen = () => {
  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <SearchSidebar />

      <ContentCard>
        <ContentHeader>
          Search results - search not available at the moment
        </ContentHeader>
        <L.Spacer v={30} h={30}>
          {/*<FormInput value="Search text" />*/}

          {/*<SearchResultItem*/}
          {/*  title="Lesson 5"*/}
          {/*  breadcrumbs="WBL Definition > Lesson 5"*/}
          {/*>*/}
          {/*  ...consectetur adipisicing elit. Amet error expedita fugit in*/}
          {/*  inventore laborum mollitia <b>perferendis placeat</b> vitae.*/}
          {/*  Aspernatur blanditiis eaque illum necessitatibus nobis saepe vitae*/}
          {/*  voluptas...*/}
          {/*</SearchResultItem>*/}
          {/*<SearchResultItem*/}
          {/*  title="Lesson 7"*/}
          {/*  breadcrumbs="WBL Definition > Lesson 7"*/}
          {/*>*/}
          {/*  ...consectetur adipisicing elit. Amet error expedita fugit in*/}
          {/*  inventore laborum mollitia <b>perferendis placeat</b> vitae.*/}
          {/*  Aspernatur blanditiis eaque illum necessitatibus nobis saepe vitae*/}
          {/*  voluptas...*/}
          {/*</SearchResultItem>*/}
          {/*<SearchResultItem*/}
          {/*  title="Lesson 2"*/}
          {/*  breadcrumbs="Legislation > Lesson 2"*/}
          {/*>*/}
          {/*  ...consectetur adipisicing elit. Amet error expedita fugit in*/}
          {/*  inventore laborum mollitia <b>perferendis placeat</b> vitae.*/}
          {/*  Aspernatur blanditiis eaque illum necessitatibus nobis saepe vitae*/}
          {/*  voluptas...*/}
          {/*</SearchResultItem>*/}
        </L.Spacer>
      </ContentCard>
    </L.Absolute>
  );
};

export { SearchScreen };
