import React, { useState } from 'react';
import { TakeTestCheckPayload, TakeTestSelectedAnswersProp } from '../../types';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../index';
import { useLanguage } from '../../language/useLanguage';
import { brandGreenColor, redColor } from '../../globals';
import Modal from 'react-modal';
import { FormInput } from '../Form/FormInput';
import { FormLabel } from '../Form/FormLabel';
import { Button } from '../Button/Button';
import { useProgressData } from '../../hooks/useProgressData';

const TakeTestResult = (props: {
  selectedAnswers: TakeTestSelectedAnswersProp;
  onTakeTestAgainClick: () => void;
  onGoBackToModuleClick?: () => void;
  finalTest: boolean;
  checksum?: string;
}) => {
  const questionIds = Object.keys(props.selectedAnswers);
  const dataKey = questionIds.join('-');
  const [showObtainModal, setShowObtainModal] = useState(false);
  const questionsWithSelectedAnswersList: TakeTestCheckPayload = [];
  questionIds.forEach((questionId) => {
    questionsWithSelectedAnswersList.push({
      questionId: Number(questionId),
      selectedAnswers: props.selectedAnswers[Number(questionId)],
    });
  });
  const { refetch: refetchProgress } = useProgressData();

  const { t } = useLanguage();

  const {
    data: testResult,
    isLoading,
    isFetching,
  } = useQuery<{
    correct: number;
    total: number;
    certificateKey?: string;
  }>(`take-test-check-${dataKey}`, () =>
    axios
      .post(`${API_URL}/test/check`, {
        questionsWithSelectedAnswersList,
        ft: props.finalTest,
        checksum: props.checksum,
      })
      .then((res) => {
        refetchProgress();
        return res.data;
      }),
  );

  if (isLoading || isFetching || !testResult) {
    return <div>Checking result..</div>;
  }

  const percent = (testResult.correct / testResult.total) * 100;
  return (
    <div>
      {percent >= 80 ? (
        <h1>
          <span
            className="fa fa-check"
            style={{ color: brandGreenColor, marginRight: 12 }}
          />
          {t('testPassed')}
        </h1>
      ) : (
        <h1>
          <span
            className="fa fa-remove"
            style={{ color: redColor, marginRight: 12 }}
          />
          {t('testFailed')}
        </h1>
      )}
      <div>
        {t('totalQuestions')}: <strong>{testResult.total}</strong>
      </div>
      <div>
        {t('correctlyAnsweredQuestions')}: <strong>{testResult.correct}</strong>
      </div>
      <div>
        {t('requiredPercentage')}: <strong>80%</strong>
      </div>

      {props.finalTest && percent >= 80 && testResult.certificateKey ? (
        <a
          style={{
            display: 'block',
            marginTop: 20,
            marginBottom: 20,
            backgroundColor: '#F8F8F8',
            padding: 10,
            cursor: 'pointer',
            border: '1px solid #DDD',
            borderRadius: 4,
            textAlign: 'center',
            fontWeight: 'bold',
          }}
          href={`${API_URL}/certificate/download?c=${testResult.certificateKey}`}
          target="_blank"
        >
          <span className="fa fa-download" /> {t('downloadCertificate')}
        </a>
      ) : null}

      {!props.finalTest ? (
        <div style={{ marginTop: 12, fontWeight: 'bold' }}>
          <a onClick={props.onTakeTestAgainClick}>{t('takeTestAgain')}</a>
          {props.onGoBackToModuleClick ? (
            <>
              {' '}
              |{' '}
              <a onClick={props.onGoBackToModuleClick}>{t('goBackToModule')}</a>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 3px #DDD',
    width: 450,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999,
  },
};

export { TakeTestResult };
