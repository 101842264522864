import React, { useState } from 'react';
import * as L from '../styled/layout';
import { headerHeight, mobileBreakPoint } from '../../globals';
import { HeaderButton } from './HeaderButton';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { useSession } from '../../hooks/useSession';
import { useLanguage } from '../../language/useLanguage';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useUIStore } from '../../hooks/useUIStore';
import { Button } from '../Button/Button';
import { FormTextarea } from '../Form/FormTextarea';
import { useMutation } from 'react-query';
import { API_URL } from '../..';
import axios from 'axios';

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 3px #DDD',
    width: 450,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 999,
  },
};

const Header = (props: { showHeaderContent?: boolean }) => {
  const { width: windowWidth } = useWindowSize();
  const { t } = useLanguage();
  const { destroySession, session } = useSession();
  const [showSendFeedbackModal, setShowFeedbackModal] = useState(false);

  const desktopView = windowWidth >= mobileBreakPoint;
  const { showMenu, toggleMenu } = useUIStore();

  const [sendFeedback] = useMutation((params: { text: string }) =>
    axios.put(`${API_URL}/send-feedback`, params).then((res) => res.data),
  );

  const [feedbackText, setFeedbackText] = useState('');

  const onSendFeedback = () => {
    sendFeedback({ text: feedbackText });
    setFeedbackText('');
    setShowFeedbackModal(false);
  };

  if (props.showHeaderContent) {
    return (
      <L.Absolute
        style={{
          borderBottom: '1px solid #DDD',
          justifyContent: 'space-between',
        }}
        bg="#FFFFFF"
        h={headerHeight}
        horizontal
        centerV
        pH={30}
      >
        <L.FlexDiv>
          <Link to="/overview">
            <img src="/logo.png" style={{ width: 132 }} />
          </Link>
        </L.FlexDiv>

        <L.FlexDiv flex style={{ alignItems: 'flex-end' }}>
          {/*<HeaderSearch />*/}
        </L.FlexDiv>

        <L.FlexDiv horizontal>
          {/*<HeaderButton*/}
          {/*  title="Support"*/}
          {/*  icon="question-circle"*/}
          {/*  path="/support"*/}
          {/*/>*/}
          {/*<HeaderButton title="My profile" icon="user" path="/my-profile" />*/}

          {desktopView ? (
            <>
              {session.adminLevel ? (
                <div>
                  <img
                    src={`/flags/${session.languageCode.toUpperCase()}@3x.png`}
                    style={{ width: 18 }}
                    alt={session.languageCode}
                  />
                </div>
              ) : null}
              {session.adminLevel ? (
                <HeaderButton title="Admin" icon="cog" path="/admin/feedback" />
              ) : null}

              {!session.adminLevel ? (
                <HeaderButton
                  title={t('sendFeedback')}
                  icon="envelope"
                  onClick={() => setShowFeedbackModal(true)}
                />
              ) : null}

              <HeaderButton
                title={t('logout')}
                icon="sign-out"
                path="/"
                onClick={() => destroySession()}
              />
            </>
          ) : (
            <a onClick={toggleMenu}>
              {showMenu ? (
                <span className="fa fa-close" />
              ) : (
                <span className="fa fa-bars" />
              )}
            </a>
          )}
        </L.FlexDiv>

        {showSendFeedbackModal ? (
          <Modal
            isOpen={showSendFeedbackModal}
            onRequestClose={() => setShowFeedbackModal(false)}
            contentLabel="Example Modal"
            style={modalStyle}
          >
            <div style={{ textAlign: 'center' }}>
              <FormTextarea
                style={{ height: 200 }}
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value)}
              />

              <Button title={t('sendFeedback')} onClick={onSendFeedback} />

              <div
                style={{
                  textAlign: 'center',
                  marginTop: 12,
                  color: '#999',
                  cursor: 'pointer',
                }}
                onClick={() => setShowFeedbackModal(false)}
              >
                {t('cancel')}
              </div>
            </div>
          </Modal>
        ) : null}
      </L.Absolute>
    );
  }

  return (
    <L.Absolute
      style={{ borderBottom: '1px solid #DDD' }}
      bg="#FFFFFF"
      h={headerHeight}
      horizontal
      centerH
      centerV
    >
      <img src="/logo.png" style={{ width: 132 }} />
    </L.Absolute>
  );
};

export { Header };
