import React from 'react';
import styled from 'styled-components';

const ContentHeader = (props: { children: React.ReactNode }) => {
  return <ContentHeaderStyle>{props.children}</ContentHeaderStyle>;
};

const ContentHeaderStyle = styled.div({
  padding: 30,
  paddingTop: 15,
  paddingBottom: 15,
  color: '#B6B6B6',
  borderBottom: '1px solid #EEE',

  a: {
    color: '#B6B6B6',
  },
});

export { ContentHeader };
