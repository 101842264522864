import React, { useState } from 'react';
import { headerHeight, redColor } from '../../globals';
import { FormInput } from '../Form/FormInput';
import { Button } from '../Button/Button';
import * as S from '../styled/styled';
import { AvailableLanguages, useLanguage } from '../../language/useLanguage';
import { useHistory } from 'react-router-dom';
import * as L from '../styled/layout';
import { useLanguageList } from '../../hooks/useLanguageList';
import { FormSelect } from '../Form/FormSelect';
import { isValidEmail } from '../../helpers/isValidEmail';
import { useSignup } from '../../hooks/useSignup';

type FormError = { field: string; text?: string };

const SignupScreen = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [signup] = useSignup();
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { data: languageList } = useLanguageList();
  const { t, changeLanguage, selectedLanguage } = useLanguage();

  const [errors, setErrors] = useState<FormError[]>([]);

  const onSignupClick = async () => {
    setLoading(true);

    const errorList: FormError[] = [];

    if (!name) {
      errorList.push({
        field: 'name',
      });
    }

    if (password !== confirmPassword) {
      errorList.push({
        field: 'password',
        text: t('passwordsMustMatch'),
      });
      errorList.push({
        field: 'confirmPassword',
      });
    }

    if (password.length < 6) {
      errorList.push({
        field: 'password',
        text: t('passwordLengthReq'),
      });
      errorList.push({
        field: 'confirmPassword',
      });
    }

    if (!isValidEmail(username)) {
      errorList.push({
        field: 'email',
      });
    }

    setErrors(errorList);

    if (errorList.length) {
      setLoading(false);
      return;
    }

    const primaryLanguageId =
      languageList?.find((lang) => lang.code === selectedLanguage)?.id || 1;

    const res = await signup({
      name,
      email: username,
      password,
      primaryLanguageId,
    });

    setLoading(false);

    if (res && !res.error) {
      history.push('/overview');
    } else {
      if (res && res.errorCode === 'emailAlreadyRegistered') {
        setErrors([{ field: 'email', text: t('emailAlreadyRegistered') }]);
      } else if (res) {
        setErrors([{ field: 'serverValidation' }]);
      } else {
        setErrors([
          { field: 'serverIssue', text: 'Technical error, please contact IT!' },
        ]);
      }
    }
  };

  return (
    <L.Absolute
      top={headerHeight}
      centerH
      style={{ overflow: 'hidden', overflowY: 'scroll' }}
    >
      <S.Card w={500} mV={30}>
        <div style={{ padding: 20 }}>
          {errors.length ? (
            <div style={{ padding: 10, color: redColor, textAlign: 'center' }}>
              {t('allFieldsAreRequired')}
              {errors
                .filter((error) => error.text)
                .map((error) => (
                  <div>{error.text}</div>
                ))}
            </div>
          ) : null}

          <FormInput
            label={t('name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!errors.find((error) => error.field === 'name')}
          />

          <FormInput
            label={t('email')}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={!!errors.find((error) => error.field === 'email')}
          />
          <FormInput
            label={t('password')}
            secureText
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.find((error) => error.field === 'password')}
          />
          <FormInput
            label={t('confirmPassword')}
            secureText
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!errors.find((error) => error.field === 'confirmPassword')}
          />

          {languageList ? (
            <FormSelect
              label={t('chooseLanguage')}
              value={selectedLanguage}
              onChange={(e) => {
                const lang = e.target.value as AvailableLanguages;
                changeLanguage(lang);
              }}
            >
              {languageList.map((language) => (
                <option value={language.code} key={language.code}>
                  {language.name}
                </option>
              ))}
            </FormSelect>
          ) : null}

          <Button
            title={loading ? t('signingUp') : t('signup')}
            onClick={onSignupClick}
          />
        </div>
      </S.Card>
    </L.Absolute>
  );
};

export { SignupScreen };
