import React, { useEffect, useState } from 'react';
import * as L from '../styled/layout';
import { brandDarkBlueColor, headerHeight } from '../../globals';
import { Sidebar } from '../Sidebar/Sidebar';
import { ContentCard } from '../Content/ContentCard';
import { ContentHeader } from '../Content/ContentHeader';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { TakeTestSelectedAnswersProp, TakeTestFullData } from '../../types';
import axios from 'axios';
import { API_URL } from '../../index';
import { ContentFooter } from '../Content/ContentFooter';
import { Button } from '../Button/Button';
import { TakeTestQuestion } from './TakeTestQuestion';
import { TakeTestResult } from './TakeTestResult';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { useLanguage } from '../../language/useLanguage';

const TakeTestScreen = () => {
  const history = useHistory();
  const routeParams = useParams<{ id: string }>();
  const id = Number(routeParams.id);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const {
    data: testData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<TakeTestFullData>(`take-test-data-${id}`, () =>
    axios.get(`${API_URL}/test/load/${id}`).then((res) => res.data),
  );
  const currentQuestion =
    testData && testData.questions && testData.questions[currentQuestionIndex]
      ? testData.questions[currentQuestionIndex]
      : null;

  const [testFinished, setTestFinished] = useState(false);
  const { t } = useLanguage();

  const [selectedAnswers, setSelectedAnswers] =
    useState<TakeTestSelectedAnswersProp>({});

  const resetAnswers = () => {
    const selectedAnswersTmp: TakeTestSelectedAnswersProp = {};

    if (testData && testData.questions && Array.isArray(testData.questions))
      testData.questions.forEach((q) => {
        if (q.questionType === 'correctOrder') {
          selectedAnswersTmp[q.id] = q.answers.map((a) => a.id);
        } else {
          selectedAnswersTmp[q.id] = [];
        }
      });

    setSelectedAnswers(selectedAnswersTmp);
  };

  useEffect(() => {
    resetAnswers();
  }, [testData]);

  const onAnswerUpdate = (questionId: number, answers: number[]) => {
    const newSelectedAnswers = { ...selectedAnswers };
    newSelectedAnswers[questionId] = answers;
    setSelectedAnswers(newSelectedAnswers);
  };

  const onFinishTestClick = () => {
    setTestFinished(true);
  };

  const onTakeTestAgainClick = () => {
    refetch();
    setTestFinished(false);
    resetAnswers();
    setCurrentQuestionIndex(0);
  };

  const onGoBackToModuleClick = () => {
    history.push(`/module/${testData?.data.moduleId}`);
  };

  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <Sidebar />
      <ContentCard>
        <ContentHeader>
          <L.FlexDiv horizontal>
            <L.FlexDiv flex>
              <Link to={`/module/${testData?.moduleData.id}`}>
                {testData?.moduleData.name} &gt; <b>{testData?.data.name}</b>
              </Link>
            </L.FlexDiv>
            <div>
              {currentQuestionIndex + 1}/{testData?.questions.length || 1}
            </div>
          </L.FlexDiv>
          <L.Spacer top={12}>
            <ProgressBar
              percent={
                ((currentQuestionIndex + 1) /
                  (testData?.questions.length || 1)) *
                100
              }
            />
          </L.Spacer>
        </ContentHeader>

        <div style={{ padding: 30, paddingTop: 15 }}>
          {testFinished ? (
            <TakeTestResult
              selectedAnswers={selectedAnswers}
              onGoBackToModuleClick={onGoBackToModuleClick}
              onTakeTestAgainClick={onTakeTestAgainClick}
              finalTest={false}
            />
          ) : (
            <div>
              {currentQuestion && !isLoading && !isFetching ? (
                <TakeTestQuestion
                  selectedAnswers={selectedAnswers[currentQuestion.id] || []}
                  question={currentQuestion}
                  onAnswerUpdate={(answers) =>
                    onAnswerUpdate(currentQuestion.id, answers)
                  }
                />
              ) : null}
            </div>
          )}
        </div>

        {!testFinished ? (
          <ContentFooter>
            <L.FlexDiv horizontal>
              <L.FlexDiv horizontal centerV>
                {currentQuestionIndex ? (
                  <a
                    onClick={() =>
                      setCurrentQuestionIndex(currentQuestionIndex - 1)
                    }
                    style={{ color: brandDarkBlueColor }}
                  >
                    <span
                      className="fa fa-chevron-left"
                      style={{ marginRight: 5 }}
                    />
                    {t('previousQuestion')}
                  </a>
                ) : null}
              </L.FlexDiv>

              <L.FlexDiv flex />
              <div style={{ width: 200 }}>
                {currentQuestionIndex >=
                (testData?.questions.length || 0) - 1 ? (
                  <Button title={t('finishTest')} onClick={onFinishTestClick} />
                ) : null}

                {currentQuestionIndex <
                (testData?.questions.length || 0) - 1 ? (
                  <Button
                    title={t('nextQuestion')}
                    onClick={() =>
                      setCurrentQuestionIndex(currentQuestionIndex + 1)
                    }
                  />
                ) : null}
              </div>
            </L.FlexDiv>
          </ContentFooter>
        ) : null}
      </ContentCard>
    </L.Absolute>
  );
};

export { TakeTestScreen };
