import React from 'react';
import * as L from '../styled/layout';
import * as S from '../styled/styled';
import { headerHeight } from '../../globals';
import { ContentCard } from '../Content/ContentCard';
import { ContentHeader } from '../Content/ContentHeader';
import { AdminSidebar } from './AdminSidebar';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../..';
import moment from 'moment';

const AdminCertificates = () => {
  const { data: certificateData, isLoading } = useQuery<{
    list: {
      id: number;
      created: string;
      name: string;
      securityToken: string;
      email: string;
    }[];
  }>(`certificate-list`, () =>
    axios.get(`${API_URL}/admin/certificate-list`).then((res) => res.data),
  );

  const certificateList = certificateData?.list || [];

  return (
    <L.Absolute top={headerHeight} horizontal style={{ overflowY: 'scroll' }}>
      <AdminSidebar />

      <ContentCard>
        <ContentHeader>Admin certificates</ContentHeader>
        {isLoading ? (
          <div style={{ padding: 30 }}>Loading...</div>
        ) : (
          <div style={{ padding: 30 }}>
            {certificateList.length ? (
              <table style={{ width: '100%' }}>
                <tr>
                  <td style={{ width: '25%' }}>Name</td>
                  <td style={{ width: '25%' }}>Email</td>
                  <td style={{ width: '25%' }}>Created</td>
                  <td style={{ width: '25%' }}>Security token</td>
                </tr>

                {certificateList.map((c) => (
                  <tr key={c.id}>
                    <td>{c.name}</td>
                    <td>{c.email}</td>
                    <td>{moment(c.created).format('DD.MM.YYYY, HH:mm')}</td>
                    <td>{c.securityToken}</td>
                  </tr>
                ))}
              </table>
            ) : (
              <div>No certificates issued</div>
            )}
          </div>
        )}
      </ContentCard>
    </L.Absolute>
  );
};

export { AdminCertificates };
