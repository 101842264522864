import styled from 'styled-components';
import { CommonStyledProps, commonStyledProps } from './common';

export const Absolute = styled.div<CommonStyledProps>(props => ({
  position: 'absolute',
  ...commonStyledProps(props),
}));

export const FlexDiv = styled.div<CommonStyledProps>(props => ({
  ...commonStyledProps(props),
}));

export const Spacer = styled.div<{
  h?: number;
  v?: number;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}>(props => {
  let marginTop = props.v || 0;
  let marginBottom = props.v || 0;
  let marginLeft = props.h || 0;
  let marginRight = props.h || 0;

  if (props.top !== undefined) marginTop = props.top;
  if (props.bottom !== undefined) marginBottom = props.bottom;
  if (props.left !== undefined) marginLeft = props.left;
  if (props.right !== undefined) marginRight = props.right;

  return {
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
  };
});
