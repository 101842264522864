import React from 'react';
import * as L from '../styled/layout';
import { useSession } from '../../hooks/useSession';

const AdminBar = (props: { enabled: boolean; onModeChange: () => void }) => {
  const { session } = useSession();
  const textColor = props.enabled ? '#e74c3c' : '#999';
  const backgroundColor = props.enabled ? '#f5d3d0' : '#F8F8F8';
  const borderColor = props.enabled ? '#e74c3c' : '#EEE';

  if (!session.adminLevel) {
    return null;
  }

  return (
    <L.FlexDiv
      horizontal
      style={{
        backgroundColor,
        padding: 10,
        marginTop: 10,
        borderRadius: 4,
        marginBottom: 20,
        border: `1px solid ${borderColor}`,
        color: textColor,
      }}
    >
      <L.FlexDiv flex>
        {props.enabled
          ? 'Admin mode is active!'
          : 'Enable admin mode to edit content!'}
      </L.FlexDiv>
      <a
        style={{
          fontWeight: 'bold',
          cursor: 'pointer',
          color: textColor,
        }}
        onClick={props.onModeChange}
      >
        {props.enabled ? 'Exit' : 'Enable'}
      </a>
    </L.FlexDiv>
  );
};

export { AdminBar };
