import React from 'react';
import { brandBlueColorLighter, brandOtherGreenColor } from '../../globals';

const CheckBox = (props: {
  checked: boolean;
  size?: number;
  onClick: () => void;
  checkBoxStyle?: 'progress' | 'neutral';
  marginLeft?: number;
  marginRight?: number;
}) => {
  const size = props.size || 16;
  const radius = size / 2;
  const fontSize = size * 0.65;
  const checkboxStyle = props.checkBoxStyle || 'progress';
  const accentColor =
    checkboxStyle === 'neutral' ? brandBlueColorLighter : brandOtherGreenColor;
  const marginLeft = props.marginLeft || 0;
  const marginRight = props.marginRight || 0;

  const containerStyle = {
    display: 'inline-block',
    width: size,
    height: size,
    borderRadius: radius,
    margin: 0,
    marginLeft,
    marginRight,
    padding: 0,
    border: `1px solid ${props.checked ? accentColor : '#CCC'}`,
    backgroundColor: props.checked ? '#FFF' : '#FFF',
    cursor: 'pointer',
    alignItems: 'center',
    textAlign: 'center' as 'center',
    fontSize,
  };

  return (
    <span
      style={containerStyle}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        props.onClick();
      }}
    >
      {props.checked && (
        <span className="fa fa-check" style={{ color: accentColor }} />
      )}
    </span>
  );
};

export { CheckBox };
